export default {
  title: 'Reset password',
  message: 'Write your new password',
  form: {
    email: 'Email',
    password: 'Password',
  },
  passwordRules: '8 characters minimum, 1 uppercase minimum',
  submit: 'Submit',
  confirmation: {
    message1: 'Your password has been updated',
    message2: 'You can now log in with your new password',
    submit: "Log in"
  },
  emailSent: `An email has been sent to you with a link to reset your password`,
}
