export default {
  title: `I wish to become a MyPubli partner`,
  ourPartners: {
    subtitle: `By supporting our project, our partners show their interest in research by helping us
    develop an even more adapted tool to the needs of authors or future authors of scientific articles so that authors
     can ultimately refocus on their job.
    Our private or public partners are not involved in editorial choice or the content of this platform.
    They do not have access to data.`
  }
}
