import form from './form'
import footer from './footer'

export default {
  form,
  footer,

  subscribe: 'SUBSCRIPTION',
  article: 'ARTICLE',
  payment: 'PAYMENT',
  confirm: 'CONFIRMATION',
  collaborators: 'COLLABORATORS',

  newArticle: 'Write a new article',
  titleSelect: 'Select your offer',
  titleDetail: "Details of your offer",
  titleLayout: "Your article template",
  titlePayment: 'Method of payment',
  titleInvit: 'Invite your collaborators',

  validationError: {
    email: `Email address is not valid`,
  }
}
