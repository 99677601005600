import login from './login'
import forgottenPassword from './forgottenPassword'
import register from './register'
import resetPassword from './resetPassword'
import activateAccount from './activateAccount'

export default {
  userAccount: 'User account',

  register,
  login,
  forgottenPassword,
  resetPassword,
  activateAccount,

  conditions: {
    beginning: 'By registering, you mention having read and accepted',
    CGU: "CGU / CGV",
    and: 'and',
    confidentiality: 'privacy policy'
  },

  readConditions: {
    CGU: {
      title: "CGU / CGV",
      text:
        'Lorem ipsum dolor sit amet arcu molestie conubia tincidunt in habitasse natoque porttitor posuere sem mi massa habitant ornare non tortor diam potenti morbi mauris nullam suscipit blandit dictum cursus senectus ligula vulputate magna himenaeos lobortis libero'
    },
    privacy: {
      title: 'Politique de confidentialité',
      text:
        'Lorem ipsum dolor sit amet arcu molestie conubia tincidunt in habitasse natoque porttitor posuere sem mi massa habitant ornare non tortor diam potenti morbi mauris nullam suscipit blandit dictum cursus senectus ligula vulputate magna himenaeos lobortis libero'
    }
  },

  confirmRegistration: {
    title: 'Registration',
    message1: 'Your registration has been taken into account',
    message2: "We sent you an email to confirm your account's creation",
    message3: 'Activate your account to access your account',
    continue: 'Continue'
  }
}
