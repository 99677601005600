import { AuthRepository } from './repository'

export default {
  async LOGIN ({ commit }, payload) {
    const { email, password } = payload

    try {
      const user = await AuthRepository.login(email, password)
      commit('SET_USER', user)
      commit('SET_USER_ID', user.id)
    } catch (err) {
      throw err
    }
  },

  /**
   *
   */
  async REGISTER ({ }, payload) {
    await AuthRepository.register(payload)
  },

  /**
   *
   */
  async LOAD_PROFILE ({ commit }, userId) {
    const user = await AuthRepository.getProfile(userId)
    commit('SET_USER', user)
  },

  /**
   *
   */
  async LOGOUT ({ commit }) {
    await AuthRepository.logout()
    commit('SET_USER', null)
    commit('SET_USER_ID', null)
  },

  /**
   *
   */
  async CHANGE_PASSWORD ({ }, payload) {
    await AuthRepository.changePasswordConnected(payload)
  },

  /**
   * Reset password
   */
  async SEND_RESET_PASSWORD_EMAIL ({ }, payload) {
    const { email } = payload

    await AuthRepository.sendResetPasswordEmail(email)
  },

  /**
   *
   */
  async RESET_PASSWORD ({ }, payload) {
    await AuthRepository.resetPassword(payload)
  },

  /**
   *
   */

  async ACTIVATE_ACCOUNT ({ }, payload) {
    const { token } = payload
    await AuthRepository.activateAccountPassword({ token })
  }
}
