export default {
  zotero: {
    loadParser: `Charger depuis une URL`,
    loadRis: `Charger depuis un fichier RIS`,
    parserError: `Une erreur est survenue lors de la récupération de votre ressource. Vous pouvez tout de même compléter le formulaire ci-dessous.`,
    risTooManyEntries: `Il y a plusieurs entrées dans le fichier RIS. Merci de n'en sélectionner qu'une seule.`,
    risTypeNotSupported: `Le type de reference n'est pas supporté.`,
    uploadRis: `Uploader un fichier RIS`,
    parserUrlError: `L'URL est incorrecte. Merci de vérifier son format.`,
    url: `Entrez l'adresse web de votre ressource`,
    types: {
      others: 'Autres documents',
      additional_figures: 'Figures supplémentaires',
      additional_tables: 'Tables supplémentaires',
      figures: 'Figures',
      tables: 'Tables',
      references: 'Références',
      conflicts_of_interest: 'Conflit d\'intérêt'
    },
    uploadTypes: {
      newspaperArticle: 'Article de presse',
      journalArticle: 'Article de journal',
      book: 'Livre',
      bookSection: 'Chapitre d’un ouvrage',
      thesis: 'Thèse',
      preprint: 'Preprint',
      conferencePaper: 'Congrès',
      magazineArticle: 'Article de magazine',
      website: 'Site internet',
      dataset: ' Jeu de données',
      encyclopediaArticle: 'Article d’encyclopédie',
      dictionaryEntry: 'Entrée de dictionnaire',
      patent: 'Brevet',
      report: 'Rapport'
    },
    save: 'Sauvegarder'
  },
  classic: {
    title: 'titre',
    legend: 'legende'
  },
  report: {
    title: "titre",
    authors: "auteurs",
    reportNumber: "numéro du rapport",
    reportType: "type de rapport",
    seriesTitle: "titre de la série",
    place: "endroit",
    institution: "institution",
    date: "date",
    pages: "pages",
    language: "langue",
    shortTitle: "Titre court",
    url: "URL",
    accessed: "consulté le",
    archive: "archive",
    locinarchive: "localisation dans l'archive",
    libraryCatalog: "catalogue de la bibliothèque",
    callNumber: "numéro d'appel",
    abstract: "abstract",
    abstractNote: "abstract note",
    extra: "extra"
  },
  book: {
    title: "titre",
    authors: "auteurs",
    firstname: "prenom",
    lastname: "nom",
    series: "series",
    seriesNumber: "Numéro de série",
    volume: "volume",
    numberOfVolumes: "nombre de volumes",
    edition: "edition",
    place: "place",
    publisher: "editeur",
    date: "date",
    originalDate: "date originale",
    numberOfPages: "nombre de pages",
    language: "langue",
    ISBN: "ISBN",
    shortTitle: "titre court",
    url: "url",
    accessed: "consulté le",
    archive: "archive",
    locinarchive: "localisation dans l'archive",
    libraryCatalog: "catalogue de la bibliothèque",
    callNumber: "numéro d'appel",
    abstract: "abstract",
    abstractNote: "abstract note",
    extra: "extra"
  },
  bookSection: {
    title: "titre",
    authors: "auteurs",
    firstname: "prenom",
    lastname: "nom",
    bookTitle: "titre du livre",
    series: "series",
    seriesNumber: "Numéro de série",
    volume: "volume",
    numberOfVolumes: "nombre de volumes",
    edition: "edition",
    place: "place",
    publisher: "editeur",
    date: "date",
    originalDate: "date originale",
    numberOfPages: "nombre de pages",
    language: "langue",
    ISBN: "ISBN",
    shortTitle: "titre court",
    url: "url",
    accessed: "consulté le",
    archive: "archive",
    locinarchive: "localisation dans l'archive",
    libraryCatalog: "catalogue de la bibliothèque",
    callNumber: "numéro d'appel",
    abstract: "abstract",
    abstractNote: "abstract note",
    extra: "extra"
  },
  conferencePaper: {
    title: "titre",
    author: "auteurs",
    date: "date",
    proceedingsTitle: "Proceedings title",
    conferenceName: "nom de la conference",
    place: "Localisation de la conference",
    publisher: "editeur",
    volume: "volume",
    pages: "pages",
    series: "series",
    language: "langue",
    DOI: "DOI",
    ISBN: "ISBN",
    shortTitle: "titre court",
    url: "url",
    accessed: "consulté le",
    archive: "archive",
    locinarchive: "localisation dans l'archive",
    libraryCatalog: "catalogue de la bibliothèque",
    callNumber: "numéro d'appel",
    abstract: "abstract",
    abstractNote: "abstract note",
    extra: "extra"
  },
  thesis: {
    title: "titre",
    authors: "auteurs",
    date: "date",
    institution: "institution",
    place: "localisation",
    numPages: "nombre de pages",
    language: "langue",
    thesisType: "type de thèse",
    url: "url",
    abstractNote: "note d'abstract",
    DOI: "DOI",
    isbn: "ISBN",
    shortTitle: "titre court",
    accessed: "consulté le",
    archive: "archive",
    locInArchive: "localisation dans l'archive",
    libraryCatalog: "catalogue de la bibliothèque",
    callNumber: "numéro d'appel",
    extra: "extra"
  },
  preprint: {
    title: "title",
    authors: "auteurs",
    date: "date",
    institution: "institution",
    place: "place",
    DOI: "DOI",
    url: "url",
    accessed: "accessed",
    abstract: "abstract",
    extra: "extra"
  },
  journalArticle: {
    title: "titre",
    authors: "auteurs",
    firstname: "prenom",
    lastname: "nom",
    publication: "publication",
    volume: "volume",
    issue: "numéro",
    pages: "pages",
    date: "date",
    series: "series",
    seriesTitle: "titre de la série",
    seriesText: "texte de la série",
    journalAbbr: "abréviation du journal",
    language: "langue",
    DOI: "DOI",
    ISSN: "ISSN",
    shortTitle: "titre court",
    url: "URL",
    accessed: "consulté le",
    archive: "archive",
    locinarchive: "localisation dans l'archive",
    libraryCatalog: "catalogue de la bibliothèque",
    callNumber: "numéro d'appel",
    abstract: "abstract",
    abstractNote: "abstract note",
    extra: "extra"
  },
  newspaperArticle: {
    title: "titre",
    authors: "auteurs",
    firstname: "prenom",
    lastname: "nom",
    publication: "publication",
    place: "lieu",
    edition: "edition",
    section: "section",
    pages: "pages",
    date: "date",
    series: "series",
    seriesTitle: "titre de la série",
    seriesText: "texte de la série",
    journalAbbr: "abréviation du journal",
    language: "langue",
    DOI: "DOI",
    ISSN: "ISSN",
    shortTitle: "titre court",
    url: "URL",
    accessed: "consulté le",
    archive: "archive",
    locinarchive: "localisation dans l'archive",
    libraryCatalog: "catalogue de la bibliothèque",
    callNumber: "numéro d'appel",
    abstract: "abstract",
    abstractNote: "abstract note",
    extra: "extra"
  },
  magazineArticle: {
    title: "titre",
    authors: "auteurs",
    firstname: "prenom",
    lastname: "nom",
    publication: "publication",
    volume: "volume",
    issue: "numéro",
    pages: "pages",
    date: "date",
    series: "series",
    seriesTitle: "titre de la série",
    seriesText: "texte de la série",
    journalAbbr: "abréviation du journal",
    language: "langue",
    ISSN: "ISSN",
    shortTitle: "titre court",
    url: "URL",
    accessed: "consulté le",
    archive: "archive",
    locinarchive: "localisation dans l'archive",
    libraryCatalog: "catalogue de la bibliothèque",
    callNumber: "numéro d'appel",
    abstract: "abstract",
    abstractNote: "abstract note",
    extra: "extra"
  },
  webpage: {
    title: "titre",
    authors: "auteurs",
    firstname: "prenom",
    lastname: "nom",
    websiteTitle: "titre du site",
    websiteType: "type de site",
    publisher: "éditeur",
    date: "date",
    shortTitle: "titre court",
    url: "URL",
    accessed: "consulté le",
    abstract: "abstract",
    extra: "extra"
  },
  dataset: {
    title: "titre",
    creator: "créateur",
    contributors: "contributeurs",
    date: "date",
    version: "version",
    publisher: "éditeur",
    url: "URL",
    accessed: "consulté le",
    archive: "archive",
    locInArchive: "localisation dans l'archive",
    DOI: "DOI",
    language: "langue",
    license: "licence",
    dataFormat: "format des données",
    numberOfFiles: "nombre de fichiers",
    dataSize: "taille des données",
    abstract: "abstract",
    extra: "extra"
  },
  encyclopediaArticle: {
    title: "titre",
    author: "auteur",
    encyclopediaTitle: "titre de l'encyclopédie",
    volume: "volume",
    pages: "pages",
    date: "date",
    originalDate: "date originale",
    publisher: "éditeur",
    url: "URL",
    accessed: "consulté le",
    archive: "archive",
    locInArchive: "localisation dans l'archive",
    libraryCatalog: "catalogue de la bibliothèque",
    callNumber: "numéro d'appel",
    abstract: "abstract",
    extra: "extra"
  },
  dictionaryEntry: {
    title: "titre",
    author: "auteur",
    dictionaryTitle: "titre du dictionnaire",
    pages: "pages",
    date: "date",
    originalDate: "date originale",
    publisher: "éditeur",
    url: "URL",
    accessed: "consulté le",
    archive: "archive",
    locInArchive: "localisation dans l'archive",
    libraryCatalog: "catalogue de la bibliothèque",
    callNumber: "numéro d'appel",
    abstract: "abstract",
    extra: "extra"
  },
  patent: {
    title: "titre",
    inventor: "inventeur",
    firstname: "prénom",
    lastname: "nom",
    patentNumber: "numéro de brevet",
    date: "date",
    country: "pays",
    assignee: "titulaire du brevet",
    url: "URL",
    accessed: "consulté le",
    archive: "archive",
    locInArchive: "localisation dans l'archive",
    patentOffice: "office des brevets",
    applicationNumber: "numéro de demande",
    abstract: "abstract",
    extra: "extra"
  },

}
