import Vue from 'vue'
import Vuex from 'vuex'
import pathify from './pathify'

import articles from './articles'
import templates from './templates'
import create from './create'
import resources from './resources'
import auth from './auth'
import users from './users'
import partners from './partners'
import faq from './faq'
import legals from './legals'
import tasks from './tasks'
import comments from './comments'
import files from './files'
import coupons from './coupons'
import mails from './mails'
import zotero from './zotero'

Vue.use(Vuex)

export default function(/* { ssrContext } */) {
  const Store = new Vuex.Store({
    plugins: [pathify.plugin],
    modules: {
      articles,
      templates,
      create,
      auth,
      users,
      resources,
      partners,
      faq,
      legals,
      tasks,
      comments,
      files,
      coupons,
      mails,
      zotero
    },

    strict: process.env.DEV
  })

  return Store
}
