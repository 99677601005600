import { legalsRepository } from './repository'

export default {
  /**
   *
   */
  async LOAD_LEGAL_INFOS({ commit }, type) {
    const legals = await legalsRepository.loadLegals(type)
    commit('SET_LEGAL_INFOS', legals)
  }
}
