import { saveAs } from 'file-saver'

export async function downloadDocX(data) {
  const file = new File([data], 'article.docx', {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  })

  saveAs(file)
}

export function downloadFile(data, filename, fileExtension) {
  const file = new File([data], filename, {
    type: getMimeType(fileExtension)
  })
  saveAs(file)
}

export function getMimeType(extension) {
  switch (extension) {
    case 'PDF':
      return 'application/pdf'
    case 'DOC':
      return 'application/msword'
    case 'DOCX':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    case 'XLS':
      return 'application/vnd.ms-excel'
    case 'XLSX':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    case 'CSV':
      return 'text/csv'
    case 'JPG':
      return 'image/jpeg'
    case 'JPEG':
      return 'image/jpeg'
    case 'PNG':
      return 'image/png'
    default:
      return 'application/octet-stream'
  }
}
