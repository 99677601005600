export default {
  title: 'Mes articles',
  hint: 'Choisissez un article pour le consulter, le modifier et accéder à son tableau de bord.',
  add: 'Nouvel article',
  write: 'Rédiger',
  empty: `Vous n'êtes rattaché à aucun article pour le moment.`,
  publish: `L'article a bien été publié.`,

  show: 'Afficher mes articles',
  filters: {
    all: 'Tous mes articles',
    author: 'Les articles pour lesquels je suis propriétaire',
    collaborator: 'Les articles pour lesquels suis contributeur / administrateur'
  },

  projects: '{nb} projets',

  card: {
    actions: {
      title: 'Actions du projet',
      write: 'Rédiger',
      preview: 'Prévisualiser',
      export: 'Exporter',
      invite: 'Inviter un contributeur',
      addTalent: 'Ajouter un prestataire',
      end: 'Publier'
    },

    dashboard: 'Tableau de bord',
    wording: 'Rédiger',

    status: {
      author: `Vous êtes propriétaire`,
      admin: `Vous êtes administrateur`,
      editor: `Vous êtes contributeur`
    }
  }
}
