import list from './list'
import edit from './edit'
import dashboard from './dashboard'
import references from './references'

export default {
  list,
  edit,
  dashboard,
  references
}
