export function calculateGlobalProgression(tasks, articleSectionSnapshots) {
    let collabCount = 0
    let total = 0
    let a = tasks
      .filter(task => articleSectionSnapshots.map(section => section.id)
      .some(sectionId => sectionId === task.scopedArticleSectionId))
    a.forEach((task) => {
        collabCount++
        total += task.completion
    })
    return collabCount > 0 ? ((total / collabCount) / 100) : 0
}
