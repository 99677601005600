export default {
  missingTemplate: false,
  template: {
    selectedTemplateId: null,
    categoryId: null,
    projectTitle: null
  },
  askedTemplate: {
    category: '',
    type: '',
    lang: 'FR'
  },
  selectedOffer: 'adapted',
  stripeSecret: null,
  currentArticle: null
}
