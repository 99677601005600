export default {
  title: 'Mot de passe oublié ?',
  message:
    "Veuillez indiquer l'adresse email associée à votre compte. Nous vous enverrons un lien de réinitialisation du mot de passe",
  email: 'Email',
  send: 'Envoyer',
  help: "Besoin d'aide ? Nous contacter",

  error: {
    email: `L'adresse email est incorrecte.`
  }
}
