import { Querier } from '@owliehq/querier'
import { $http } from 'boot/axios'

export class TemplatesRepository {
  /**
   * load template
   */
  static loadTemplate(id) {
    try {
      const url = Querier.query({ baseUrl: `/templates/${id}` })
        .populate('templateSnapshots')
        .generate()

      return $http.get(url)
    } catch (err) {
      throw err
    }
  }

  /**
   * load templates
   */
  static loadTemplates() {
    try {
      const url = Querier.query({ baseUrl: `/templates/` })
        .rawConditions({ published: true })
        .populate('lastTemplateSnapshot')
        .generate()

      return $http.get(url)
    } catch (err) {
      throw err
    }
  }

  /**
   * load templates
   */
  static loadEssentialTemplates() {
    try {
      const url = Querier.query({ baseUrl: `/templates/` })
        .populate('lastTemplateSnapshot')
        .rawConditions({
          is_essential: true
        })
        .generate()

      return $http.get(url)
    } catch (err) {
      throw err
    }
  }

  /**
   *
   *
   */
  static async getDefaultLanguageTemplate(lang) {
    try {
      const templateLang = await $http.get(`/defaultlanguagetemplates/${lang}`)
      return templateLang.templateId ?? null
    } catch (err) {
      return null
    }
  }
  /**
   *
   */
  static loadCategories() {
    const url = Querier.query({ baseUrl: `/categories` })
      .populate('templates.templateSnapshots')
      .generate()

    return $http.get(url)
  }
}
