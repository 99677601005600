export default {
  title: 'Je souhaite faire partie de la communauté des prestataires',
  informations: {
    section1: `Vous accompagnez des auteurs d’articles scientifiques médicaux ou en sciences biomédicales ? Vous êtes traducteur, rédacteur, graphiste, méthodologiste, ou statisticien ?`,
    section2: `Rejoignez la communauté des prestataires de MyPubli !`,
    section3: `En tant que talent de MyPubli, vous recevrez des demandes de clients à la recherche de vos compétences. En répondant à leurs besoins, vous leur permettrez d’atteindre leur objectif : rédiger un article, un poster, un diaporama ou un document scientifique de qualité.`,
    section4: `Pour rejoindre gratuitement la communauté, il vous suffit de remplir le formulaire ci-dessous. Un mail de confirmation vous précisera si vous répondez aux critères de sélection de MyPubli.`,
    section5: `Lorsqu'une demande de partenariat arrive sur la plateforme, nous nous chargeons de la transmettre par email à tous les prestataires concernés. Ensuite, la balle est dans votre camp pour décrocher la mission.`,
    section6: `Si vous avez la moindre question, n'hésitez pas à nous contacter, nous vous répondrons dans les meilleurs délais.`
  },
  form: {
    title: 'Formulaire'
  },
  charter: {
    title: 'Charte des clients'
  }
}
