export default {
  title: `Je souhaite devenir partenaire de MyPubli`,
  ourPartners: {
    subtitle: `En soutenant notre projet, nos partenaires démontrent leur intérêt pour la recherche en nous aidant
    à développer un outil toujours plus adapté aux besoins des auteurs ou futurs auteurs d’articles scientifiques,
     pour finalement permettre aux auteurs de se recentrer sur leur métier.
    Nos partenaires privés ou publics ne sont pas impliqués dans le choix éditorial ou le contenu de cette plateforme.
    Ils n’ont pas d’accès aux données.`
  }
}
