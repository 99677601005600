export default {
  title: 'À propos',
  description: {
    paragraph_1: `Parce que vous êtes les mieux placés pour présenter vos recherches et leurs résultats et parce  que votre temps est précieux, MyPubli vous propose une nouvelle façon de rédiger vos publications scientifiques médicales ou biomédicales.`,
    paragraph_2: `MyPubli vous accompagne pour atteindre vos objectifs de publication au travers de ses valeurs : compétences, collaboration et innovation.`,
    paragraph_3: `En collaborant, vous rejoignez l’équipe MyPubli.`
  },

  committee: {
    title: 'Le comité scientifique et éthique',
    description: `Il est sollicité pour tout sujet d'ordre éthique et pour aider MyPubli à anticiper les changements de demain.`
  },

  founders: {
    title: 'Les membres fondateurs',
    description: `Tous partagent leurs savoirs et expérience.`
  },

  members: {
    herve: {
      name: `Hervé Maisonneuve`,
      description: `Médecin de santé publique, ancien directeur de l'évalutaion à l'ANAES et consultant en rédaction scientifique (www.redactionmedicale.fr).`
    },
    monique: {
      name: `Monique Rothan-Tondeur`,
      description: `Infirmière, doctorat de santé publique, habilitation à diriger des recherches. Titulaire de la chaire de Recherche Sciences Infirmières.`
    },
    bernard: {
      name: `Bernard Asselain`,
      description: `Médecin biostatisticien, Ancien directeur du service de biostatistique de l'Institut Curie et président de la société française de biométrie.`
    },
    emmanuelle: {
      name: `Emmanuelle Kempf`,
      description: `Médecin oncologue, Chef de clinique assistante.`
    },
    fabienne: {
      name: `Fabienne Péretz`,
      description: `Docteure vétérinaire. Virologue et rédactrice médicale. Gérante de la société Abelia Science.`
    },
    martin: {
      name: `Martin Duracinsky`,
      description: `Clinicien en Médecine interne à l'hôpital Bicêtre et Chercheur à l'unité PROs et à l'URC-Eco de l'Hôpital Hôtel-Dieu à Paris.`
    },
    olivier: {
      name: `Olivier Chassany`,
      description: `Professeur de médecine à l'université de Paris, à l'INSERM et à l'URC-Eco de l'Hôtel-Dieu à Paris.`
    },
    alain: {
      name: `Alain Assouline`,
      description: `Fondateur et président du réseau des écoles WebForce3 et président du CINOV-Numérique.`
    },
    michel: {
      name: `Michel Grivaux`,
      description: `Pneumologue, ancien directeur du centre de recherche clinique et ancien chef de service de pneumologie de l'hôpital de Meaux`
    },
    cecile: {
      name: `Cécile Péretz`,
      description: `Ingénieur en informatique et mathématiques financières. Étudiante en école de commerce.`
    }
  }
}
