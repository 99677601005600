export default {
  offerTitle: 'Our offers',
  offerDescription: 'Provide your team with the best tool for writing and publishing their scientific articles.',
  offer1: {
    name: 'essential',
    label: 'Essential',
    price: '€174',
    amount: '17400' // Si ce montant est une somme = 17,400
  },
  offer2: {
    name: 'adapted',
    label: 'Adapted',
    price: '€327',
    amount: '32700'
  },
  quote1: {
    name: 'custom',
    label: 'Customized',
    description: 'On quote or purchase order'
  },
  quote2: {
    name: 'multilicences',
    label: 'Multi-Licenses',
    description: 'On quote or purchase order'
  },
  taxes: 'All taxes included',
  choiceBtn: 'Choose this offer',
  quoteBtn: 'Request a quote',

  feature1: "Appropriate article template",
  feature2: "Collaborative writing (up to 12 contributors)",
  feature3: 'Choice of article language: French or English',
  feature4: 'Tracked changes',
  feature5: 'Bibliography generated automatically',
  feature6: 'Personalized advice',
  feature7: 'Shared storage space',
  feature8: 'Resources accessible throughout the writing process',

  detailsBtn: "Discover the tool",

  advantage1: 'Secure platform',
  advantage2: 'Support and advice',
  advantage3: `Independent and free of any conflict of interest`,
  advantage4: 'Developed by experts',
  advantage5: 'Open to all medical specialties'
}
