export default {
  title: "S'identifier",

  form: {
    email: 'Email',
    password: 'Mot de passe'
  },
  forgottenPassword: 'Mot de passe oublié ?',
  submit: "S'identifier",

  error: {
    email: `L'adresse email est incorrecte.`,
    password: 'Doit contenir 8 caractères avec 1 majuscule minimum',
    invalid: 'Identifiants invalides ou non reconnus.',
    inactive: `Ce compte n'a pas été validé`
  }
}
