export default {
  welcome: 'Welcome',
  form: {
    avatar: 'Avatar',
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name',
    currentPwd: 'Current password',
    newPwd: 'New password',
    confirmPwd: 'Confirm password',
    professional: 'Professional',
    degree: 'Degree',
    affiliation: 'Affiliation',
    conflict: `Conflict of interest`,
    viewCurrentConflictOfInterestFile: `View my current document`,
    conflictFileHint: `Select your conflict of interest document (PDF)`,
    conflitsGuideUrl: `http://icmje.org/disclosure-of-interest/`,
    password: 'Password',
    changePassword: 'Change password',
    submit: 'Submit',
    confirm: 'Your modifications have been saved',
    error: {
      required: 'This field is required',
      password: 'Must contain 8 characters with 1 uppercase minimum',
      confirm: 'One or more fields are not valid',
      sameAs: `Passwords don't match`,
      email: `Email is not valid`,
    }
  }
}
