export default {
  clement: {
    name: 'Clément',
    profil: 'Pharmacy student',
    content: `I had had a few courses on medical writing, but I had never been confronted with writing an article.
    I had heard about the platform and I thought it was the good time to try it. The tool is easy to use.
    Following the detailed plan and the advice for each part of the article has de-dramatized the writing of my thesis.
    My thesis supervisor could easily follow the writing process, and leave comments.`
  },
  saleh: {
    name: 'Saleh',
    profil: `Research engineer`,
    content:
      "A few words to express my satisfaction with your website. The quality of the writing tool and the protection of the work are two indispensable aspects for my job. As a researcher looking for collaborators,  the possibility of contacting certified service providers is one of your most distinguishing services."
  },
  angeline: {
    name: 'Angéline',
    profil: 'Nurse',
    content: "I really appreciated the step-by-step support, adapted to my writing level."
  },
  veronique: {
    name: 'Véronique',
    profil: 'Chief Scientific Officer',
    content:
      "I am very happy to see that the MyPubli project has materialized. Congratulations to the team for the hard work on this new intelligent tool which will help young (and less young) researchers and authors to write high-quality publications in an efficient way. Nordic Pharma is proud to have been part of the MyPubli adventure."
  },
  pascal: {
    name: 'Pascal',
    profil: 'Pneumologist',
    content:
      "Formatting articles for journals is long, and contacting again co-authors also often takes a lot of time. I entrusted this to MyPubli, and thus freed myself from these tedious constraints."
  }
}
