const requiresAuth = true

const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    redirect: '/home',
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('pages/Home.vue')
      },
      {
        path: 'about-us',
        name: 'aboutUs',
        component: () => import('pages/AboutUs.vue')
      },
      {
        path: 'faq',
        name: 'FAQ',
        component: () => import('pages/FAQ.vue')
      },
      {
        path: 'partners',
        name: 'partners',
        component: () => import('pages/partners/Partners.vue')
      },
      {
        path: 'become-partner',
        name: 'becomePartner',
        component: () => import('pages/partners/BecomePartner.vue')
      },
      {
        path: 'quote-request',
        name: 'quoteRequest',
        component: () => import('pages/QuoteRequest.vue')
      }
    ]
  },
  //offers
  {
    path: '/offers',
    component: () => import('layouts/MainLayout.vue'),
    redirect: 'offers/mycoach',
    children: [
      {
        path: 'mycoach',
        name: 'myCoach',
        component: () => import('pages/offers/MyCoach.vue')
      },
      {
        path: 'myformation',
        name: 'myFormation',
        component: () => import('pages/offers/MyFormation.vue')
      },
      {
        path: 'mysolutions',
        name: 'mySolutions',
        component: () => import('pages/offers/MySolutions.vue')
      }
    ]
  },
  //auth
  {
    path: '/auth',
    component: () => import('layouts/MainLayout.vue'),
    redirect: 'auth/login',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('pages/auth/Login.vue')
      },
      {
        path: 'forgotten-password',
        name: 'forgottenPassword',
        component: () => import('pages/auth/ForgottenPassword.vue')
      },
      {
        path: 'confirm',
        name: 'confirmRegistration',
        component: () => import('pages/auth/ConfirmRegistration.vue')
      },
      {
        path: 'read/:page',
        name: 'readConditions',
        component: () => import('pages/auth/ReadConditions.vue')
      },
      {
        path: 'reset-password',
        name: 'resetPassword',
        component: () => import('pages/auth/ResetPassword.vue')
      },
      {
        path: 'validate-account',
        name: 'validateAccount',
        component: () => import('pages/auth/ActivateAccount.vue')
      }
    ]
  },

  //User
  {
    path: '/account',
    component: () => import('layouts/MainLayout.vue'),
    redirect: 'account/profile',
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: () => import('pages/account/Profile.vue')
        //meta: { requiresAuth }
      }
    ]
  },

  //Talents
  {
    path: '/talents',
    component: () => import('layouts/MainLayout.vue'),
    redirect: 'talents/find',
    children: [
      {
        path: 'find',
        name: 'findTalent',
        component: () => import('pages/talents/TalentFind.vue')
        //meta: { requiresAuth }
      },
      {
        path: 'join',
        name: 'joinTalent',
        component: () => import('pages/talents/TalentJoin.vue')
        //meta: { requiresAuth }
      }
    ]
  },

  //Articles
  {
    path: '/articles',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: 'edit/:id',
        name: 'articleEdit',
        component: () => import('pages/articles/Edit.vue'),
        meta: { requiresAuth }
      },

      {
        path: 'list',
        name: 'articleList',
        component: () => import('pages/articles/List.vue'),
        meta: { requiresAuth }
      }
    ]
  },

  //Resources
  {
    path: '/resources',
    name: 'resources',
    component: () => import('layouts/MainLayout.vue'),
    redirect: '/resources/categories',
    children: [
      {
        path: 'categories',
        name: 'resourcesCategories',
        component: () => import('pages/resources/Categories.vue')
        //meta: { requiresAuth }
      },
      {
        path: ':themeId/:categoryId',
        name: 'resourcesList',
        component: () => import('pages/resources/List.vue')
        //meta: { requiresAuth }
      },
      {
        path: 'submit',
        name: 'resourcesSubmit',
        component: () => import('pages/resources/Submit.vue'),
        meta: { requiresAuth }
      },
      {
        path: 'validation',
        name: 'resourcesSubmitValidation',
        component: () => import('pages/resources/SubmitValidation.vue'),
        meta: { requiresAuth }
      }
    ]
  },
  //Articles

  {
    path: '/article/create',
    name: 'subscribe',
    component: () => import('layouts/CreateLayout.vue'),
    meta: { requiresAuth }
  },

  {
    path: '/article/asktemplate',
    name: 'askTemplate',
    component: () => import('layouts/ArticleDashboardLayout.vue'),
    children: [
      {
        path: 'asktemplate',
        name: 'askTemplate',
        component: () => import('pages/articles/dashboard/AskArticleTemplateDashboard.vue'),
        meta: { requiresAuth }
      }
    ]
  },

  {
    path: '/articles/dashboard',
    component: () => import('layouts/ArticleDashboardLayout.vue'),
    children: [
      {
        path: ':id',
        name: 'articleDashboard',
        component: () => import('pages/articles/dashboard/MainInfos.vue'),
        meta: { requiresAuth }
      },
      {
        path: ':id/calendar',
        name: 'articleCalendar',
        component: () => import('pages/articles/dashboard/Calendar.vue'),
        meta: { requiresAuth }
      },
      {
        path: ':id/files',
        name: 'articleFiles',
        component: () => import('pages/articles/dashboard/Files.vue'),
        meta: { requiresAuth }
      },
      {
        path: ':id/files/:key',
        name: 'articleFilesSection',
        component: () => import('pages/articles/dashboard/Files.vue'),
        meta: { requiresAuth }
      },
      {
        path: ':id/subscription',
        name: 'articleSubscription',
        component: () => import('pages/articles/dashboard/Subscription.vue'),
        meta: { requiresAuth }
      },
      {
        path: ':id/template',
        name: 'articleTemplate',
        component: () => import('pages/articles/dashboard/Template.vue'),
        meta: { requiresAuth }
      },
      {
        path: ':id/collaborators',
        name: 'articleCollaborators',
        component: () => import('pages/articles/dashboard/Collaborators.vue'),
        meta: { requiresAuth }
      }
    ]
  },
  // legals

  {
    path: '/legals',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: 'personal-data',
        name: 'personalData',
        component: () => import('pages/legals/Legals.vue')
      },

      {
        path: 'legal-notices',
        name: 'generalConditions',
        component: () => import('pages/legals/Legals.vue')
      },
      {
        path: 'mentions',
        name: 'legalMentions',
        component: () => import('pages/legals/Legals.vue')
      },
      {
        path: 'privacy-policy',
        name: 'privacyPolicy',
        component: () => import('pages/legals/Legals.vue')
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
