export default {
  getCollaborators: state => {
    return state.collaborators
  },
  testArticle: state => {
    let result = true
    for (let i in state.articleLayout) {
      if (state.articleLayout[i] === '') result = false
    }

    return result
  }
}
