export default {
  resources: 'Resources and Tips',
  linkCopied: 'The sharing link has been copied to your clipboard.',
  search: {
    searchResult: 'Search for a resource',
    keywords: 'Keywords',
    results: 'results',
    noResult: 'Sorry! We couldn\'t find any results for your search.',
    noResult2: 'Our resource database is regularly updated and expanded. Maybe next time?'
  },

  list: {
    noCategories: 'No categories',
    noResources: 'No resources',
    submitResource: {
      message1: 'Would you like to contribute to the creation of these resources?',
      message2: 'Submit your resource below',
      button: 'Contribute to resources'
    }
  },

  submitPage: {
    header: 'Submit a Resource',
    theme: 'Choose the theme',
    category: 'Choose the category',
    otherCategory: '(No matching category)',
    type: 'What type of resource would you like to submit?',
    pdf: 'PDF',
    uploadPDF: 'Upload your PDF',
    image: 'An image',
    video: 'A video',
    text: 'A text',
    or: 'or',
    link: 'A link to another page',
    uploadImage: 'Upload your image',
    url: 'URL link',
    textLabel: 'Text',
    title: 'Title of your resource',
    hideAuthor: 'By checking this box, your name will not appear on the resource',
    send: 'Send'
  },
  submitValidation: {
    title: 'Thank you for your submission!',
    success: 'Your resource has been successfully submitted',
    validation: 'After validation by our team, it will be made available online',
    mail: 'You will be notified by email once your resource is validated',
    continue: 'Continue'
  },
  formation: {
    title: 'Training',
    description: 'Would you like to receive training or train your colleagues?',
    askQuote: 'Request a quote'
  },
  collaboration: {
    title: 'Collaboration',
    description: 'Would you like to contribute to the creation of these resources?',
    contribute: 'Contribute to resources'
  },
}
