import offer from './offer'
import shortcuts from './shortcuts'
import testimonials from './testimonials'

export default {
  offer,
  shortcuts,
  testimonials,

  section: {
    lastResources: 'Nouvelles ressources et astuces',
    steps: 'Rédigez pas à pas',
    talents: 'Nos prestataires',
    testimonials: 'Témoignages',
    partners: 'Nos partenaires',
    video: 'L’outil de rédaction collaboratif',
    videoDescription: 'L’outil a été spécialement conçu pour rédiger et publier son article scientifique médical',
    startBtn: 'Je me lance !',
    contact: 'Contactez-nous',
    findOutMore: 'En savoir plus',
    fomDescription: 'Découvrez plus en détail notre fonctionnement',
    audit: 'Audit des publications',
  },

  tool: {
    collaborativeToolTitle: 'Notre Accompagnement',
    collaborativeToolDescription: 'Nous vous accompagnons selon vos objectifs et besoins, tout au long de la rédaction de votre article ! ',
    toolTitle: 'L’outil de rédaction collaboratif',
    toolDescription : 'L’outil a été spécialement conçu pour rédiger et publier son article scientifique médical.',
    collaborationTitle: 'Collaboration',
    collaborationDescription: 'Votre espace de travail collaboratif :',
    collaborationDescriptionList: {
      1: 'Rédaction collaborative nombre de contributeurs illimité (calendrier, jalons, commentaires, alertes,...).',
      2: 'Espace de stockage partagé sécurisé (spécifique et adapté à la rédaction d’articles scientifiques.',
      3: 'Suivi et sauvegarde des modifications.',
      4: 'Choix de la langue de rédaction : français ou anglais.',
    },
    customizeTitle: 'Personalisation',
    customizeDescription: 'Choix du type d’étude et de la revue choisie :',
    customizeDescriptionList: {
      1: 'Template d’article adapté au type d’étude et aux instructions aux auteurs de la revue.',
      2: 'Aide et conseils adaptés au type d’étude et à la revue.',
      3: 'Bibliographie automatique adaptée au type d’étude et à la revue.',
      4: 'Astuces accessibles tout au long de la rédaction.',
    },
    demoVideo: 'Voir la démo',

  },

  heroBar: {
    title: 'MyPubli',
    text:'Votre alliée en rédaction et publication scientifique biomédicale',
    button: 'Je me Lance '
  },

  lastResources: {
    all: 'Voir toutes les ressources'
  },

  collaborativeTool: {
    card1: 'Contactez-nous pour définir votre objectif et votre besoin',
    card2: "Connectez-vous sur l’outil de rédaction collaboratif avec votre équipe.",
    card3: 'Rédigez collaborativement et accompagnée par nos experts.',
    card4: 'Laissez-vous guider par l’outil pour rédiger efficacement.',
    card5: 'Votre article est prêt à être publié !'
  },

  talents: {
    title: 'Prestataire',
    info:
      "MyPubli propose un réseau de compétences professionnelles pour un accompagnement sur-mesure à tous les stades de votre projet de rédaction.",
    find: {
      description:
        'Vous recherchez un professionnel (traducteur, rédacteur, graphiste, méthodologiste ou statisticien...) ?',
      link: 'Trouver un prestataire'
    },
    join: {
      description:
        'Vous êtes un professionnel et souhaitez rejoindre notre communauté de prestataires pour mettre à disposition vos services ?',
      link: 'Rejoindre la communauté'
    }
  },
  audits: {
    info:"Vous avez des données, des résultats, des études et vous pensez pouvoir publier les résultats ? Nous réalisons un audit pour définir une feuille de route des publications à réaliser, en étudiant ce qui est en votre possession et selon vos objectifs. \n" +
      "Ce dispositif comprend 4 phases : ",
    launchTitle: 'Lancement',
    launchDescription: 'En comprenant parfaitement vos besoins lors d’un premier rendez-vous, nous concevons un audit sur mesure qui vous permettra d\'atteindre vos objectifs de manière efficace. Notre approche centrée sur vous garantit une collaboration étroite et une optimisation de vos résultats.',
    analysisTitle: 'Analyse',
    analysisDescription: 'Notre équipe effectue une analyse approfondie pour évaluer ce qui peut être publié en fonction des données existantes ou des études à mener. Cette étape nous permet de déterminer les impacts potentiels de vos publications et de vous fournir des recommandations personnalisées.',
    coConstructionTitle: 'Coconstruction',
    coConstructionDescription: 'Nous élaborerons une feuille de route collaborative, en identifiant les priorités clés pour atteindre vos objectifs. En unissant nos forces, nous assurerons une planification efficace, alignée sur vos ressources disponibles, afin d’optimiser les résultats.',
    finalizationTitle: 'Finalisation',
    finalizationDescription: 'La présentation de la feuille de route vous orientera dans les actions à entreprendre. Avec une approche clairement définie, vous bénéficierez d\'une direction stratégique solide, facilitant la mise en œuvre efficace de votre plan d\'action. ',
  },
  partners: {
    info:
      "Les partenaires nous ont aidés à construire la plateforme mais n'interviennent pas dans le contenu",
    toPartner: {
      title: 'Vous souhaitez devenir partenaire de MyPubli ?',
      description:
        'Associez votre entreprise à la plateforme, pour ensemble, faire rayonner la rédaction et la publication médicale et biomédicale, et au-delà améliorer la santé de tous',
      button: 'Devenir partenaire'
    }
  },

  contact: {
    phoneNumber: '+33 x xx xx xx xx',
    lastname: 'Nom',
    firstname: 'Prénom',
    mail: 'Email',
    phone: 'Téléphone',
    content: 'Message',
    send: 'Envoyer',
    confirmation: 'Votre message a bien été envoyé',
    errors: 'Vérifiez que tous les champs soient remplis correctement'
  }
}
