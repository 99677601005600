export default {
  assignedTo: 'Attribué à :',
  on: 'Sur ',
  invite: 'Inviter',

  menu: {
    dashboard: 'Tableau de bord',
    calendar: 'Calendrier',
    template: `Gabarit de l'article`,
    files: 'Documents',
    collaborators: 'Contributeurs',
    subscription: 'Souscription'
  },

  infos: {
    showCalendar: 'Voir dans le calendrier',
    inProgress: '{nb} en cours',
    done: '{nb} terminée(s)',
    progression: `Progression de l'article`,
    deadline: 'Deadline',
    noDeadline: 'Aucune deadline',
    tasks: 'Tâches',
    nextMilestone: 'Votre prochain jalon',
    currentTemplate: 'Gabarit choisi',
    choosenTemplate: 'Revue choisie',
    compatibleGuidelines: 'Guidelines compatibles',
    lang: 'Langue',
    noTask: 'Aucune tâche',
    noMilestone: 'Aucun jalon',
    lastUpdate: `Dernier ajout le {date} à {time}`,
    author: 'Administrateur'
  },

  template: {
    select: 'Titre de la revue choisie',
    url: 'Url de la charte de la vue',
    guidelines: 'Lignes directrices : ',
    guidelinesHint: 'Vous rédigez un article adapté à la grille : ',
    lang: 'Langue de rédaction : ',
    confirmation: `Votre demande de gabarit d'article pour la Revue "{title}" a bien été prise en compte.`,
    ask: 'Vous ne trouvez pas votre gabarit ? Contactez-nous',
    switch: {
      title: 'Changement de gabarit',
      text: `Attention, certains éléments de votre article peuvent être effacés si vous choisissez un autre gabarit.`
    }
  },

  files: {
    conflicts_of_interest: `Conflits d'intérêt`,
    references: 'Références',
    tables: 'Tables',
    figures: 'Figures',
    additional_tables: 'Tables supplémentaires',
    additional_figures: 'Figures supplémentaires',
    others: 'Autres documents',
    hint: `Téléchargez ou glissez vos {element} dans cette partie. Ils seront joints à votre article.`,
    downloadHint: `Cliquez ici pour télécharger votre fichier {type}`,
    downloadInscriptionHint: `Uploadez votre carte édudiant ({type})`,
    delete: {
      title: `Suppression d'un document`,
      text: 'Cette action est irrévocable. Souhaitez-vous continuer ?'
    },
    referencesDisplay: {
      title: `Affichage par titre`,
      author: `Affichage par auteur`
    }
  },

  collaborations: {
    scope: {
      owner: 'Auteur',
      admin: 'Administrateur',
      editor: 'Editeur',
      reader: 'Lecteur'
    },
    invited: 'en attente',
    delete: {
      title: `Suppression d'un contributeur`,
      text: 'Cette action est irrévocable.Souhaitez-vous continuer ?'
    },

    create: {
      title: 'Inviter des contributeurs',
      text: 'Saisissez les adresses mail des personnes que vous souhaitez inviter :'
    },

    authorOnly: `Seul l'auteur de l'article est autorisé à effectuer cette action`,
    invitationsSuccess: `Un email a été envoyé pour chaque contributeur. Les contributeurs qui ne sont pas inscrits seront ajoutés automatiquement après leur inscription sur la plateforme`
  }
}
