import { Querier } from '@owliehq/querier'
import { $http } from 'boot/axios'
export class ZoteroRepository {
  static async fetchURL (payload) {
    const { url } = payload
    const params = {
      url: url
    }
    return await $http.get(`articleFiles/zoterometadata`, { params })
  }
}
