import { CouponsRepository } from './repository'

export default {
  async CHECK_ONE_COUPON({ commit }, payload) {
    try {
      const { discount } = await CouponsRepository.checkOneCoupon(payload)
      commit('SET_CURRENT_COUPON', { code: payload.code, discount })
    } catch (err) {
      commit('SET_CURRENT_COUPON', null)
      throw err
    }
  }
}
