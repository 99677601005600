export default {
  title: 'I want to be part of the community of service providers',
  informations: {
    section1: `Do you accompany authors of medical or biomedical scientific articles? Are you a translator, writer, graphic designer, methodologist, or statistician?`,
    section2: `Join the MyPubli service provider community!`,
    section3: `As a MyPubli talent, you will receive requests from clients looking for your skills. By responding to their needs, you will allow them to achieve their goal: write an article, a poster, a slideshow or a quality scientific document.`,
    section4: `To join the community for free, just fill out the form below. A confirmation email will tell you if you meet the selection criteria of MyPubli.`,
    section5: `When a partnership request arrives on the platform, we take care of transmitting it by email to all the concerned service providers. Then the ball is in your court to land the mission.`,
    section6: `If you have any questions, do not hesitate to contact us, we will answer you as soon as possible.`
  },
  form: {
    title: 'Form'
  },
  charter: {
    title: 'Client Charter'
  }
}
