import { ArticlesRepository } from './repository'
import { TasksRepository } from 'src/store/tasks/repository'
import { downloadDocX } from 'src/utils/export'

import clone from 'clone'
import { calculateGlobalProgression } from 'src/utils/globalCollaborationCalculator'

import { uid } from 'quasar'

export default {
  async CREATE_ARTICLE({ commit }, payload) {
    return ArticlesRepository.createArticle(payload)
  },

  /**
   * get all articles
   */
  async LOAD_ARTICLES({ commit }, offsetPagination) {
    try {
      if (offsetPagination === 0) commit('SET_ARTICLES', [])
      let articles = await ArticlesRepository.loadArticles(offsetPagination)

      // start stopwatch
      const start = new Date().getTime()

      const tasksPromises = articles.map(article => {
        const sectionIds = article.lastArticleVersion.articleSectionSnapshots.map(section => section.id)
        return TasksRepository.loadTasksBySectionIds(sectionIds)
      })

      const tasksResults = await Promise.all(tasksPromises)

      articles = articles.map((article, index) => {
        const tasks = tasksResults[index]
        article.globalProgression = calculateGlobalProgression(
          tasks,
          article.lastArticleVersion.articleSectionSnapshots
        )
        return article
      })

      console.log('LOAD_ARTICLES', new Date().getTime() - start)

      commit('PUSH_ARTICLES', articles)
      return articles
    } catch (err) {
      throw err
    }
  },

  /**
   * get one article
   */
  async LOAD_ARTICLE({ commit }, id) {
    try {
      const article = await ArticlesRepository.loadArticle(id)
      const conflictOfInterest = await ArticlesRepository.loadConflictOfInterest(id)
      commit('SET_ARTICLE', article)
      commit('SET_ARTICLE_FILES', [...article.articleFiles, ...conflictOfInterest])
    } catch (err) {
      throw err
    }
  },

  /**
   * get article documents
   */
  async LOAD_ARTICLE_FILES({ commit, state }) {
    try {
      const { id } = state.article

      const articleFiles = await ArticlesRepository.loadArticleFiles(id)

      commit('SET_ARTICLE_FILES', articleFiles)
    } catch (err) {
      throw err
    }
  },

  /**
   * get article collaborations
   */
  async LOAD_COLLABORATIONS({ commit }, id) {
    try {
      const collaborations = await ArticlesRepository.loadCollaborations(id)
      commit('SET_COLLABORATIONS', collaborations)
    } catch (err) {
      throw err
    }
  },

  /**
   * get article versions
   */
  async LOAD_ARTICLE_VERSIONS({ commit, dispatch }, params) {
    try {
      const versions = await ArticlesRepository.loadArticleVersions(params)
      let lastVersion = versions[0]
      lastVersion.articleSectionSnapshots = lastVersion.articleSectionSnapshots.sort(
        (a, b) => a.orderWriting - b.orderWriting
      )
      commit('SET_CURRENT_VERSION', lastVersion)
      commit('SET_VERSIONS', versions)
    } catch (err) {
      throw err
    }
  },

  /**
   * get article history
   */
  async LOAD_ARTICLE_VERSIONS_HISTORY({ commit, dispatch }, params) {
    try {
      const versions = await ArticlesRepository.loadArticleVersionsHistory(params)
      commit('PUSH_VERSIONS', versions)
    } catch (err) {
      throw err
    }
  },

  /**
   * delete article collaboration
   */
  async DELETE_COLLABORATION({}, collaborationId) {
    try {
      return await ArticlesRepository.deleteCollaboration(collaborationId)
    } catch (err) {
      throw err
    }
  },

  async UPDATE_COLLABORATION_SCOPE({}, { collaborationId, scope }) {
    try {
      return await ArticlesRepository.updateCollaboration(collaborationId, { scope })
    } catch (err) {
      throw err
    }
  },

  async UPDATE_ARTICLE_DEADLINE({ commit, state }) {
    const { deadline, id } = state.article
    const article = await ArticlesRepository.saveArticle({ deadline, id })
    commit('SET_ARTICLE', article)
  },

  async UPDATE_ARTICLE_TITLE({ commit, state }) {
    const { title, id } = state.article
    const article = await ArticlesRepository.saveArticle({ title, id })
    commit('SET_ARTICLE', article)
  },

  async GET_PENDING_COLLABORATORS({ commit }, articleId) {
    commit('SET_PENDING_COLLABORATORS', [])
    const invitations = await ArticlesRepository.getPendingInvitations()
    const filteredInvitations = invitations.filter(invitation => {
      for (let i = 0; i <= invitation.contexts.length; i++) {
        return invitation.contexts[i].articleId === articleId
      }
    })
    commit('SET_PENDING_COLLABORATORS', filteredInvitations)
  },

  async INVITE_COLLABORATORS({ state }, collaborators) {
    const articleId = state.article.id
    const responses = {
      collaborations: [],
      invitations: [],
      errors: []
    }
    collaborators.forEach(async collaborator => {
      const response = await ArticlesRepository.inviteCollaborator({
        email: collaborator.email,
        scope: collaborator.scope,
        articleId
      })

      switch (response.code) {
        case 0:
          responses.errors.push(collaborator.email)
          break

        case 1:
          responses.collaborations.push(collaborator.email)
          break

        case 2:
          responses.invitations.push(collaborator.email)
          break
      }
    })
    return responses
  },

  async CREATE_ARTICLE_VERSION({ commit, state }, payload) {
    const { currentVersion } = state
    const { switchTemplate } = payload

    const updatedVersion = await ArticlesRepository.cloneVersion({
      version: currentVersion,
      updateTemplate: true,
      switchTemplate
    })

    commit('SET_CURRENT_VERSION', updatedVersion)
  },

  async SAVE_MULTIPLE_SECTIONS({ commit, dispatch, state }) {
    function isJSON(str) {
      try {
        return JSON.parse(str) && !!str
      } catch (e) {
        return false
      }
    }

    const sections = Object.values(state.currentDocEditedSections).map(section => {
      const cloned = clone(section)
      if (isJSON(cloned.value)) {
        cloned.value = JSON.parse(cloned.value)
      }
      return cloned
    })
    // UPDATE BULK
    const returnedPs = await ArticlesRepository.saveMultipleSection({
      sections: sections,
      articleId: state.article.id,
      saveId: uid()
    })
    commit('SET_CURRENT_DOC_EDITED_SECTIONS', {})
    // const newArticleVers = await ArticlesRepository.createNewArticleVersion(state.article.lastArticleVersion)
    // return Promise.all(returnedPs, newArticleVers)
    commit('SET_CURRENT_VERSION', returnedPs)
    return returnedPs
  },

  async GET_LAST_SECTION_VERSION({ commit }, sectionId) {
    const section = await ArticlesRepository.getSection(sectionId)
    commit('UPDATE_SPECIFIC_CURRENT_SECTION', section)
    return section
  },

  /**
   * save sections
   */
  async SAVE_SECTION({}, section) {
    const cloned = clone(section)

    function isJSON(str) {
      try {
        return JSON.parse(str) && !!str
      } catch (e) {
        return false
      }
    }

    if (isJSON(cloned.value)) {
      cloned.value = JSON.parse(cloned.value)
    }

    try {
      return await ArticlesRepository.saveSection(cloned)
    } catch (err) {
      throw err
    }
  },
  /**
   * export article
   */
  async EXPORT_ARTICLE({ state }, articleId) {
    try {
      const doc = await ArticlesRepository.exportArticle(articleId)

      downloadDocX(doc)
    } catch (err) {
      throw err
    }
  },
  /**
   * end article (publish it)
   */
  async END_ARTICLE({ state }, articleId) {
    try {
      return await ArticlesRepository.endArticle(articleId)
    } catch (err) {
      console.error('actions: endArticle error ' + JSON.stringify(err))
      throw err
    }
  },
  /**
   * get conflicts of interests for article
   */
  async GET_CONFLICTSOFINTEREST({ commit }, articleId) {
    try {
      const conflictsOfInterest = await ArticlesRepository.loadConflictOfInterest(articleId)
      commit('SET_CONFLICTS_OF_INTEREST', conflictsOfInterest)
    } catch (err) {
      throw err
    }
  },
  /**
   * get references display from localstorage
   */
  async GET_REFERENCES_DISPLAY({ commit }) {
    try {
      commit('SET_REFERENCES_DISPLAY', localStorage.getItem('referenceDisplay'))
    } catch (err) {
      throw err
    }
  },

  /**
   * get references display from localstorage
   */
  async SET_REFERENCES_DISPLAY({ commit }, val) {
    try {
      localStorage.setItem('referenceDisplay', val)
      commit('SET_REFERENCES_DISPLAY', val)
    } catch (err) {
      throw err
    }
  }
}
