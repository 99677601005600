import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import { make } from 'vuex-pathify'

export default {
  namespaced: true,
  getters: {
    ...getters,
    ...make.getters(state)
  },
  mutations: {
    ...mutations,
    ...make.mutations(state)
  },
  actions,
  state
}
