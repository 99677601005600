export default {
  title: 'Forgotten password',
  message:
    "Write your email address and we'll send you a link to reset your password.",
  email: 'Email',
  send: 'Send',
  help: "Need help? Contact us",

  error: {
    email: `Email address is not valid`,
  }
}
