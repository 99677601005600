import { CreateRepository } from '../create/repository'

export default {
  async CREATE_ONE_ARTICLE({ commit, state, rootState }, coupon) {
    let payload = {}
    payload.title = state.template.projectTitle
    payload.templateId = state.template.selectedTemplateId
    payload.offerName = 'adapted'
    if (!coupon) {
      const currentCoupon = rootState.coupons.currentCoupon
      if (currentCoupon) payload.coupon = rootState.coupons.currentCoupon.code
    } else {
      payload.coupon = coupon
    }
    const { article, secret } = await CreateRepository.createOneArticle(payload)
    commit('SET_CURRENT_ARTICLE', article)
    commit('SET_STRIPE_SECRET', secret)
  },

  async INVITE_COLLABORATORS({ state }, collaborators) {
    const articleId = state.currentArticle.id
    const responses = {
      collaborations: [],
      invitations: [],
      errors: []
    }
    collaborators.forEach(async collaborator => {
      const response = await CreateRepository.inviteCollaborator({
        email: collaborator.email,
        scope: collaborator.scope,
        articleId
      })
      switch (response.code) {
        case 0:
          responses.errors.push(collaborator.email)
          break

        case 1:
          responses.collaborations.push(collaborator.email)
          break

        case 2:
          responses.invitations.push(collaborator.email)
          break
      }
    })
    return responses
  },

  async ASK_TEMPLATE({ state }) {
    await CreateRepository.askTemplate(state.askedTemplate)
  }
}
