import Vue from 'vue'

export default {
  UPDATE_CURRENT_DOC_EDITED_SECTIONS(state, section) {
    Vue.set(state.currentDocEditedSections, section.id, section)
    const currentSectionIndex = state.currentVersion.articleSectionSnapshots.findIndex(
      stateSection => section.id === stateSection.id
    )
    Vue.set(state.currentVersion.articleSectionSnapshots, currentSectionIndex, section)
  },
  CLEAN_CURRENT_DOC_EDITED_SECTIONS(state) {
    Vue.set(state, 'currentDocEditedSections', {})
  },
  UPDATE_SPECIFIC_CURRENT_SECTION(state, section) {
    const sectionSnapshotIndex = state.currentVersion.articleSectionSnapshots.findIndex(
      sectionSnapshot => sectionSnapshot.id === section.id
    )
    if (sectionSnapshotIndex)
      Vue.set(state.currentVersion.articleSectionSnapshots, sectionSnapshotIndex, section)
  },
  PUSH_ARTICLES(state, articles) {
    state.articles = [...state.articles, ...articles]
  },
  PUSH_VERSIONS(state, versions) {
    state.versions = [...state.versions, ...versions]
  },
  CLEAN_CURRENT_ARTICLE(state) {
    Vue.set(state, 'article', null)
    Vue.set(state, 'currentDocEditedSections', {})
    Vue.set(state, 'temporaryArticle', null)
    Vue.set(state, 'conflictsOfInterest', null)
    Vue.set(state, 'pendingCollaborators', null)
    Vue.set(state, 'historyVersion', null)
    Vue.set(state, 'currentVersion', null)
    Vue.set(state, 'versions', [])
    Vue.set(state, 'collaborations', [])
    Vue.set(state, 'articleFiles', [])
    Vue.set(state, '', [])
  }
}
