import layout from './layout.js'
import articles from './articles'
import create from './create'
import users from './users'
import homepage from './homepage'
import auth from './auth'
import resources from './resources'
import about from './about.js'
import faq from './faq.js'
import quoteRequest from './quoteRequest.js'
import talents from './talents'
import legals from './legals'
import partners from './partners'

export default {
  create,
  layout,
  articles,
  auth,
  homepage,
  resources,
  users,
  about,
  faq,
  quoteRequest,
  talents,
  legals,
  partners,
  show: 'Show',
  handle: 'Manage',
  save: 'Save',
  submit: 'Submit',
  delete: 'Delete',
  edit: 'Edit',
  choose: 'Choose',
  add: 'Add',
  next: 'Next',
  cancel: 'Cancel',
  restrictedFiles:
  'File format not allowed. Accepted formats are: {filesAccepted}',
  success: 'Data saved',
  failed: `An error occurred, please try again in a few moments.`
}
