import { FilesRepository } from './repository'

export default {
  async DELETE_ARTICLE_FILE({}, file) {
    return FilesRepository.deleteArticleFile(file)
  },

  async UPLOAD_ARTICLE_FILE({}, payload) {
    return FilesRepository.createArticleFile(payload)
  },

  async UPDATE_FILE({}, payload) {
    return FilesRepository.updateArticleFile(payload)
  },

  async SET_MANUALLY_EDITED_DATA_ARTICLE_FILE({}, payload) {
    return FilesRepository.setManuallyEditedDataArticleFile(payload)
  },

  async LOAD_FILE_CATEGORIES({ commit }) {
    const fileCategories = await FilesRepository.loadFileCategories()
    commit('SET_FILE_CATEGORIES', fileCategories)
  },

  async LOAD_CONTENT_TYPES_BY_FILE_CATEGORY({ commit }) {
    const contentTypes = await FilesRepository.loadContentTypesByFileCategory()
    commit('SET_CONTENT_TYPES', contentTypes)
  },

  async GET_FILE_METADATA({}, fileId) {
    return await FilesRepository.getFileMetadata(fileId)
  },

  async UPDATE_CURRENT_FILE({}, payload) {
    return await FilesRepository.updateFileMetadata(payload)
  },

  async GET_FILE({}, payload) {
    return await FilesRepository.downloadFile(payload)
  }
}
