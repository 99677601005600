import { UsersRepository } from './repository'

export default {
  /**
   *
   */
  async LOAD_USER ({ commit }, id) {
    if (!id) return

    const user = await UsersRepository.getUser(id)
    commit('SET_USER', user)
  },

  /**
   *
   */
  async SAVE_USER ({ commit }, user) {
    const newUser = await UsersRepository.saveUser(user)
    commit('SET_USER', newUser)
  },

  /**
   *
   */
  async SAVE_CONFLICTOFINTEREST ({ commit }, payload) {
    return await UsersRepository.saveConflictOfInterest(payload)
  },

  /***
   *
   */

  async LOAD_PROFESSIONS ({ commit }) {
    const professions = await UsersRepository.getProfessions()
    commit('SET_PROFESSIONS', professions)
  }
}
