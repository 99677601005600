import { $http } from 'boot/axios'
export class FilesRepository {
  static _createArticleFileForm(payload) {
    let fd = new FormData()
    if (payload.key) {
      if (!payload.key.type) {
        if (payload.key.name.endsWith('.doc'))
          fd.append('key', new Blob([payload.key], { type: 'application/msword' }))
        else if (payload.key.name.endsWith('.docx'))
          fd.append(
            'key',
            new Blob([payload.key], {
              type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            })
          )
        else fd.append('key', payload.key)
      } else {
        fd.append('key', payload.key)
      }
    }
    if (payload.url) fd.append('url', payload.url)
    fd.append('articleId', payload.articleId)
    fd.append('name', payload.name)
    fd.append('fileCategory', payload.fileCategory)
    if (payload.metadata) fd.append('metadata', JSON.stringify(payload.metadata))
    else fd.append('metadata', JSON.stringify({}))
    fd.append('contentType', payload.contentType ? payload.contentType : '')

    return fd;
  }

  /**
   * Create article file
   * @param {} payload
   */
  static async createArticleFile(payload) {
    let fd = FilesRepository._createArticleFileForm(payload)

    return $http.post(`/articleFiles`, fd, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  static async updateArticleFile(payload) {
    let fd = FilesRepository._createArticleFileForm(payload)

    return $http.put(`/articleFiles/${payload.file.id}/upload`, fd, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  /**
   * Delete article file
   * @param {} payload
   */
  static async deleteArticleFile(payload) {
    const { id } = payload

    return $http.delete(`/articleFiles/${id}`)
  }

  /**
   * set file infos article file
   * @param {} payload
   */
  static async setManuallyEditedDataArticleFile(payload) {
    const { articleFileId } = payload
    return $http.put(`/articleFiles/${articleFileId}`, payload)
  }

  /**
   * get file categories
   */
  static async loadFileCategories() {
    const res = await $http.get('/enums/articleFiles/file_categories')
    return res
  }

  /**
   * get content types
   */
  static async loadContentTypesByFileCategory() {
    return $http.get('/enums/articleFiles/content_types_by_file_categories')
  }

  /**
   * get metadata from file
   */
  static async getFileMetadata(id) {
    const metadata = await $http.get(`/articleFiles/${id}/metadata`)
    return metadata
  }

  /**
   * update metadata
   */
  static async updateFileMetadata(payload) {
    return await $http.put(`articleFiles/${payload.key}`, {
      metadata: JSON.stringify(payload.metadata),
      name: payload.name
    })
  }

  /**
   * download file
   */

  static async downloadFile(payload) {
    const { id, type, key } = payload
    const cacheBypass = key === undefined ? '' : `?v=${encodeURI(key)}`
    return await $http.get(`${type}/${id}/download${cacheBypass}`, { responseType: 'blob',  })
  }
}
