import Vue from 'vue'

export default {
  UPDATE_TASKS(state, tasks) {
    tasks.forEach(toSaveTask => {
      const taskExistingIndex = state.tasks.findIndex(task => task.id == toSaveTask.id)
      if (taskExistingIndex >= 0) {
        Vue.set(state.tasks, taskExistingIndex, toSaveTask)
      } else {
        state.tasks.push(toSaveTask)
      }
    })
  },
  REMOVE_TASK(state, taskId) {
    const taskIndex = state.tasks.findIndex(task => task.id === taskId)
    state.tasks.splice(taskIndex, 1)
  }
}
