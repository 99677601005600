import { Querier } from '@owliehq/querier'
import { $http, $httpWithoutRefresh } from 'boot/axios'
import { decodeToken } from 'src/utils/tokens'

export class AuthRepository {
  static async login(email, password) {
    try {
      const { accessToken, refreshToken } = await $httpWithoutRefresh.post('/auth/login', {
        email,
        password
      })
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.removeItem('logoutAsked')
      const { id } = decodeToken(accessToken)

      return AuthRepository.getProfile(id)
    } catch (err) {
      await this.logout()
      if (err.response.data) {
        throw err.response.data
      } else {
        throw error
      }
    }
  }

  static async getProfile(id) {
    const url = Querier.query({ baseUrl: `/users/${id}` })
      .populate('userFiles')
      .generate()
    return $http.get(url)
  }

  static async getProfileUserFiles(id) {
    const url = Querier.query({ baseUrl: `/users/${id}` })
      .populate('userFiles')
      .generate()
    return $http.get(url)
  }

  static async register(payload) {
    let fd = new FormData()
    fd.append('email', payload.email)
    fd.append('password', payload.password)
    fd.append('firstName', payload.firstName)
    fd.append('lastName', payload.lastName)
    fd.append('profession', payload.profession)
    fd.append('key', payload.key)
    fd.append('name', payload.name)
    return $httpWithoutRefresh.post(`/auth/signup`, fd, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  static logout() {
    localStorage.setItem('logoutAsked', true)

    const token = localStorage.getItem('refreshToken')
    if (!token) return

    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')

    return $httpWithoutRefresh.post('/auth/logout', { refreshToken: token })
  }

  static changePasswordConnected(payload) {
    return $http.post('/auth/reset-password-connected', payload)
  }

  static sendResetPasswordEmail(email) {
    return $http.post('/auth/forgot-password', { email, app: 'webapp' })
  }

  static resetPassword(payload) {
    return $http.post('/auth/reset-password', payload)
  }

  static activateAccountPassword(payload) {
    return $http.post('/auth/activate-account', payload)
  }
}
