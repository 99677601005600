import offer from './offer'
import shortcuts from './shortcuts'
import testimonials from './testimonials'

export default {
  offer,
  shortcuts,
  testimonials,

  section: {
    lastResources: 'New resources and tips',
    steps: 'Write step by step',
    talents: 'Our providers',
    testimonials: 'Testimonials',
    partners: 'Our partners',
    video: 'The collaborative writing tool',
    videoDescription: 'The tool has been specifically designed for writing and publishing medical scientific articles.',
    startBtn: 'Let\'s get started!',
    contact: 'Contact us',
    findOutMore: 'Find out more',
    fomDescription: 'Discover in more detail how we work',
    audit: 'Publication audit',
  },

  tool: {
    collaborativeToolTitle: 'Our Support',
    collaborativeToolDescription: 'We support you according to your goals and needs throughout the writing of your article!',
    toolTitle: 'The collaborative writing tool',
    toolDescription: 'The tool has been specifically designed for writing and publishing medical scientific articles.',
    collaborationTitle: 'Collaboration',
    collaborationDescription: 'Your collaborative workspace:',
    collaborationDescriptionList: {
      1: 'Collaborative writing with unlimited contributors* (calendar, milestones, comments, alerts, etc.).',
      2: 'Secure shared storage space (specific and adapted to scientific article writing).',
      3: 'Tracking and backup of changes.',
      4: 'Choice of writing language: French or English.',
    },
    customizeTitle: 'Customization',
    customizeDescription: 'Choice of study type and selected journal:',
    customizeDescriptionList: {
      1: 'Article template adapted to the study type and journal author guidelines.',
      2: 'Help and advice tailored to the study type and journal.',
      3: 'Automatic bibliography adapted to the study type and journal.',
      4: 'Accessible tips throughout the writing process.',
    },
    demoVideo: 'Watch the demo',
  },

  heroBar: {
    title: 'MyPubli',
    text: 'Your ally in biomedical scientific writing and publishing',
    button: 'Let\'s get started'
  },

  lastResources: {
    all: 'See all resources'
  },

  collaborativeTool: {
    card1: 'Contact us to define your goal and needs',
    card2: 'Log in to the collaborative writing tool with your team.',
    card3: 'Write collaboratively and be supported by our experts.',
    card4: 'Let the tool guide you for efficient writing.',
    card5: 'Your article is ready to be published!',
  },

  talents: {
    title: 'Provider',
    info:
      'MyPubli offers a network of professional skills for tailor-made support at all stages of your writing project.',
    find: {
      description:
        'Looking for a professional (translator, writer, graphic designer, methodologist, or statistician...)?',
      link: 'Find a provider',
    },
    join: {
      description:
        'Are you a professional and want to join our community of providers to offer your services?',
      link: 'Join the community',
    },
  },

  audits: {
    info: "You have data, results, studies, and you think you can publish the findings? We conduct an audit to define a roadmap for the publications to be made, by studying what you have and according to your objectives. \n" +
      "This process consists of 4 phases: ",
    launchTitle: 'Launch',
    launchDescription: 'By fully understanding your needs during a first meeting, we design a customized audit that will allow you to achieve your goals effectively. Our client-centered approach ensures close collaboration and optimization of your results.',
    analysisTitle: 'Analysis',
    analysisDescription: 'Our team performs an in-depth analysis to assess what can be published based on existing data or required studies. This step allows us to determine the potential impact of your publications and provide you with personalized recommendations.',
    coConstructionTitle: 'Co-Construction',
    coConstructionDescription: 'We will develop a collaborative roadmap, identifying key priorities to achieve your objectives. By joining forces, we ensure efficient planning, aligned with your available resources, to optimize the results.',
    finalizationTitle: 'Finalization',
    finalizationDescription: 'The presentation of the roadmap will guide you in the actions to take. With a clearly defined approach, you will benefit from a solid strategic direction, facilitating the effective implementation of your action plan.',
  },

  partners: {
    info:
      "Partners have helped us build the platform but do not intervene in the content",
    toPartner: {
      title: 'Do you want to become a partner of MyPubli?',
      description:
        'Associate your company with the platform to together promote medical and biomedical scientific writing and publishing, and beyond, improve health for all.',
      button: 'Become a partner',
    },
  },

  contact: {
    phoneNumber: '+33 x xx xx xx xx',
    lastname: 'Last Name',
    firstname: 'First Name',
    mail: 'Email',
    phone: 'Phone',
    content: 'Message',
    send: 'Send',
    confirmation: 'Your message has been sent successfully',
    errors: 'Please check that all fields are filled in correctly',
  },
}
