export default {
  resource: null,
  resources: [],
  lastResources: [],
  searchResources: [],

  themes: [],
  categories: [],

  theme: null,
  category: null,

  resourceTypes: []
}
