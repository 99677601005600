import { format } from 'date-fns'
import locale from 'date-fns/locale/fr'
import { i18n } from 'boot/i18n'

export default async ({ Vue }) => {
  Vue.filter('formatTime', date => {
    return date ? format(new Date(date), 'HH:mm') : ''
  })

  Vue.filter('formatDate', date => {
    return date ? format(new Date(date), 'dd/MM/yyyy') : ''
  })

  Vue.filter('formatDateTime', date => {
    return date ? format(new Date(date), 'dd/MM/yyyy HH:mm') : ''
  })

  Vue.filter('formatFullDate', date => {
    return date ? format(new Date(date), 'dd MMMM yyyy', { locale }) : ''
  })

  Vue.filter('formatUserProfession', profession => {
    switch (profession) {
      case 'student':
        return i18n.t('users.professions.student')
      case 'regular':
        return i18n.t('users.professions.regular')
      default:
        return null
    }
  })

  Vue.filter('formatPrice', rawPrice => {
    return (rawPrice / 100).toFixed(2) + ' €'
  })
}
