import { TemplatesRepository } from './repository'
import { store } from 'vuex-pathify'

export default {
  /**
   * get template
   */
  async LOAD_TEMPLATE({ commit }, templateId) {
    try {
      const template = await TemplatesRepository.loadTemplate(templateId)

      commit('SET_TEMPLATE', template)
    } catch (err) {
      throw err
    }
  },

  async LOAD_TEMPLATES({ commit }) {
    const templates = await TemplatesRepository.loadTemplates()

    commit('SET_TEMPLATES', templates)
  },

  async LOAD_ESSENTIAL_TEMPLATES({ commit }) {
    const templates = await TemplatesRepository.loadEssentialTemplates()
    commit('SET_TEMPLATES', templates)
  },

  /**
   *
   *
   */
  async LOAD_DEFAULTLANGUAGETEMPLATE({ commit, state }) {
    const langs = state.availableLanguages
    let templatesIds = {}
    for (const lang in langs) {
      const templateId = await TemplatesRepository.getDefaultLanguageTemplate(langs[lang])
      if (templateId) templatesIds[langs[lang]] = templateId
    }
    commit('SET_DEFAULT_LANGUAGES_TEMPLATES', templatesIds)
  },

  /**
   *
   */
  async LOAD_CATEGORIES({ commit }) {
    const categories = await TemplatesRepository.loadCategories()
    const categoriesFilteredActiveTemplates = categories.filter(category => {
      const existingCategories = category.templates.filter(currTemplate => currTemplate.published)
      if (existingCategories.length) return category
    })
    commit('SET_CATEGORIES', categoriesFilteredActiveTemplates)
  }
}
