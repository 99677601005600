import Vue from 'vue'

export default {
  UPDATE_COMMENTS(state, comments) {
    state.comments = comments
  },

  REMOVE_COMMENT(state, commentId) {
    const commentIndex = state.comments.findIndex(comment => comment.id === commentId)
    state.comments.splice(commentIndex, 1)
  }
}
