import { Querier } from '@owliehq/querier'
import { $http } from 'boot/axios'

export class CouponsRepository {
  /**
   * check one coupon
   */
  static checkOneCoupon(payload) {
    const url = Querier.query({ baseUrl: `/coupons/amount` }).generate()

    return $http.post(url, payload)
  }
}
