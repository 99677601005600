export default {
  title: "Register",
  subtitle: 'Create your user account to start writing your article',
  form: {
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm password',
    firstName: 'first name',
    lastName: 'last name',
    profession: 'Account type'
  },
  error: {
    profession: 'Account type is required',
    email: `Email address is not valid`,
    password: 'Must contain 8 characters with 1 uppercase minimum',
    confirmPassword: 'Passwords do not match',
    firstName: 'First name is required.',
    lastName: 'Last name is required.',
  },
  passwordRules: 'Must contain 8 characters with 1 uppercase minimum',
  submit: "Register"
}
