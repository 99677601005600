import state from './state'

import { make } from 'vuex-pathify'

export default {
  namespaced: true,
  getters: {
    ...make.getters(state)
  },
  state
}
