import { $http } from 'src/boot/axios'
import { email } from 'vuelidate/lib/validators'

export class CreateRepository {
  static async createOneArticle(payload) {
    const { article, secret } = await $http.post('/articles', payload)
    return { article, secret }
  }

  static async inviteCollaborator(payload) {
    const { email, scope, articleId } = payload
    try {
      // return {code: 2, invitation} if userInvitation is created
      // return {code: 1, collaboration} if collaboration is created
      return await $http.post('/collaborations/invitation', { email, scope, articleId })
    } catch (error) {
      return { code: 0 }
    }
  }

  static async askTemplate(payload) {
    await $http.post('/templates/ask', payload)
  }
}
