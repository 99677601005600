import { Querier } from '@owliehq/querier'
import { $http } from 'boot/axios'

export class UsersRepository {
  static async saveUser (user) {
    const { id } = user

    if (!id) return $http.post('/users', user)

    return $http.put(`/users/${id}`, user)
  }

  static async saveConflictOfInterest (payload) {
    let fd = new FormData()
    fd.append('key', payload.key)
    fd.append('name', payload.name)

    return $http.put(`/userfiles/conflictofinterest`, fd)
  }

  static async getUser (id) {
    const url = Querier.query({ baseUrl: `/users/${id}` }).generate()
    return $http.get(url)
  }

  static getProfessions () {
    return $http.get('/enums/users/professions')
  }
}
