import login from './login'
import forgottenPassword from './forgottenPassword'
import register from './register'
import resetPassword from './resetPassword'
import activateAccount from './activateAccount'

export default {
  userAccount: 'Compte utilisateur',

  register,
  login,
  forgottenPassword,
  resetPassword,
  activateAccount,

  conditions: {
    beginning: 'En vous inscrivant, vous mentionnez avoir lu et accepté',
    CGU: "CGU / CGV",
    and: 'et',
    confidentiality: 'la politique de confidentialité'
  },

  readConditions: {
    CGU: {
      title: "CGU / CGV",
      text:
        'Lorem ipsum dolor sit amet arcu molestie conubia tincidunt in habitasse natoque porttitor posuere sem mi massa habitant ornare non tortor diam potenti morbi mauris nullam suscipit blandit dictum cursus senectus ligula vulputate magna himenaeos lobortis libero'
    },
    privacy: {
      title: 'Politique de confidentialité',
      text:
        'Lorem ipsum dolor sit amet arcu molestie conubia tincidunt in habitasse natoque porttitor posuere sem mi massa habitant ornare non tortor diam potenti morbi mauris nullam suscipit blandit dictum cursus senectus ligula vulputate magna himenaeos lobortis libero'
    }
  },

  confirmRegistration: {
    title: 'Inscription',
    message1: 'Votre inscription à bien été pris en compte.',
    message2: "Nous vous avons envoyé un mail avec un lien d'activation de votre compte personnel",
    message3: 'Activez votre compte pour pouvoir vous identifier',
    continue: 'Continuer'
  }
}
