import { $http } from 'boot/axios'

export class MailsRepository {
  static sendContactMail(payload) {
    return $http.post(`/mails/contact`, payload)
  }
  static sendReminderMail(payload) {
    return $http.post(`/mails/reminder`, payload)
  }
}
