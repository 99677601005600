export default {
  unavailable: `Article editing is not available on small screens. Please edit your article on a tablet in landscape mode or on a computer `,
  history: 'History',
  historyLoadMore: 'Load more',
  preview: `Article preview`,
  dashboard: 'Dashboard',
  articlelabel: 'My articles',
  save: 'Save',
  lastSave: 'Last save',
  versionDate: 'Version from {date} at {time}',
  versionDateLight: '{date} at {time}',
  saveBeforeClose: 'Did you save your changes before closing this window?',
  sidebarCard: {
    HELP: 'Tips and help',
    references: 'References',
    COMMENTS: 'Comments',
    tables: 'Tables',
    figures: 'Figures',
    conflicts_of_interest: "Conflicts of interest",
    additional_tables: 'Supplementary tables',
    additional_figures: 'Supplementary figures',
    noFile: `No such document has been attached to this article yet.`,
    addUrlResource: 'Add URL',
    uploadRessource: 'Add file',
    uploadFile: 'Add',
    validate: 'Send',
    cancel: 'Cancel',
    shapingHelp: 'Formatting help',
    gotchaDialog: 'Understood', // A verifier - 10/01/2023
    shapingHelpDialogContent:
    'This is the help box for formatting the content',
    referenceHint:
    'Type in or copy your reference. Remember you can get formatting help.',

    contentTypesLabels: {
      article: 'Article',
      book: 'Book',
      website: 'Website',
      table: 'Table',
      picture: 'Picture',
      other: 'Other'
    },

    newRef: 'New reference'
  },

  comments: {
    none: `No comment saved on this section. Please make one using the comment box`,
    all: 'All comments',
    infos: `Made by {author} on {section}`,
    add: 'Add a comment',
    delete: {
      title: `Deleting of comment`,
      text: 'This action is definitive. Do you wish to proceed?'
    },
    saveWarning: "Warning: Adding a comment will save your article as is."
  },
// //
  numberOfWords: '/ {numberOfWords} mots',

  updateTemplate: {
    title: `Your template version is not up to date`,
    text: `You can stay on your current version, or update it. Be careful, some elements of your article may be deleted if you choose to update.`,
    action: 'Update',
    cancel: 'Keep my version'
  },

  tasks: {
    delete: {
      title: `Deleting of task`,
      text: 'This action is definitive. Do you wish to proceed?'
    }
  },

  toolbars: {
    edit: {
      history: 'Version history',
      undo: 'Undo',
      redo: 'Revenir en avant',
      save: 'Save the version',
    },

    visibility: {
      preview: 'Preview',
      export: 'Export',
    },

    infos: {
      stats: 'Progress',
      abstract: `See the abstract`,
      feedback: 'All comments',
    },

    users: {
      add: 'Invite',
      calendar: 'Calendar'
    }
  },

  bloc: {
    help: `Tips and help`,
    bold: `Bold`,
    italic: `Italic`,
    strike: `Strike`,
    undo: `Undo`,
    redo: `Redo`,
    addComment: `Add a comment`,
    showComments: `Show comments`,
    red: `Red`,
    task: `Add a task`,
    alertCollaborators: 'Alert collaborators',
    sendAlert: 'Send',
    alertCollaboratorsSuccess: 'Message sent',
    alertCollaboratorsError: 'Error while sending message',
    conflicts: "Interests conflicts",
    references: 'References',
    tables: 'Tables',
    figures: 'Figures',
    additional_tables: 'Supplementary tables',
    additional_figures: 'Supplementary figures',
  },

  specialBlocs: {
    addMultipleSingleText: 'Add a new text',
  },

  referencesDisplay: {
    title: `title`,
    author: `author`
  }
}
