import axios from 'axios'

import tokenProvider from 'axios-token-interceptor'
// import { decodeToken } from 'src/utils/tokens'

const baseURL = process.env.API_BASE_URL || 'http://localhost:3000'

const $http = axios.create({
  baseURL
})

const $httpWithoutRefresh = axios.create({
  baseURL
})

$http.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error.response) {
      // debug error
      console.error(error.response.data)
      console.error(error.response.status)
      console.error(error.response.headers)
    } else if (error.request) {
      // No answer
      console.error(error.request)
    } else {
      console.error('Error', error.message)
    }
    throw error
  }
)

$httpWithoutRefresh.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error.response) {
      // debug error
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    } else if (error.request) {
      // No answer
      console.log(error.request)
    } else {
      console.log('Error', error.message)
    }

    throw error
  }
)

export default ({ store, redirect }) => {
  const refreshTokenFromAuthServer = async () => {
    const refreshToken = localStorage.getItem('refreshToken')

    if (!refreshToken) return

    const payload = {
      refreshToken
    }

    try {
      const { accessToken } = await axios
        .post(`${baseURL}/auth/refresh`, payload)
        .then(response => response.data)

      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('expiry', new Date())

      return accessToken
    } catch (error) {
      if (error?.response?.status === 404) {
        console.log('Not connected...')
      } else {
        console.error(error)
      }

      try {
        // await store.dispatch('auth/LOGOUT')
      } catch (err) {
        console.error('Logout done')
      }

      // redirect({ path: '/auth/login' })

      return
    }
  }

  const getToken = tokenProvider.tokenCache(refreshTokenFromAuthServer, { maxAge: 15000 })

  $http.interceptors.request.use(
    tokenProvider({
      getToken
    })
  )

  // const { type } = decodeToken(refreshToken)
}
export { $http, $httpWithoutRefresh, baseURL }
