export default {
  clement: {
    name: 'Clément',
    profil: 'Étudiant en pharmacie',
    content: `J'avais eu quelques cours sur la rédaction médicale, mais je n'avais jamais été confronté à la rédaction d'un article. J'avais entendu parler de la plateforme et je me suis dit que c'était l'occasion d'essayer. L'outil est facile à prendre en main. Suivre le plan détaillé et les conseils pour chaque partie de l'article a dédramatisé la rédaction de ma thèse. Mon directeur de thèse pouvait suivre facilement ma rédaction, et laisser des commentaires.`
  },
  saleh: {
    name: 'Saleh',
    profil: `Ingénieur d'étude`,
    content:
      "Un petit mot pour exprimer ma satisfaction avec votre site. La  qualité de l'outil de rédaction et la sécurisation du travail sont deux  aspects indispensables pour mon travail. Étant un chercheur à la recherche de contributeurs, la possibilité de contacter des prestataires certifiés est un des vos services qui vous distingue."
  },
  angeline: {
    name: 'Angéline',
    profil: 'Infimière',
    content: "J’ai beaucoup apprécié l’accompagnement pas à pas, adapté à mon niveau de rédaction."
  },
  veronique: {
    name: 'Véronique',
    profil: 'Chief Scientific Officer',
    content:
      "Je suis très heureuse de voir que le projet MyPubli s'est concrétisé.  Félicitations à l'équipe pour le travail acharné sur ce nouvel outil  intelligent, qui aidera les jeunes (et moins jeunes) chercheurs et auteurs à écrire des publications de haute qualité d'une manière  efficace. Nordic Pharma est fier d'avoir pu faire partie de l'aventure  MyPubli."
  },
  pascal: {
    name: 'Pascal',
    profil: 'Pneumologue',
    content:
      "La mise en forme des articles pour les revues est longue, et relancer les coauteurs prend aussi souvent beaucoup de temps. J'ai confié cela à MyPubli, et je me suis ainsi libéré de ces contraintes fastidieuses."
  }
}
