export default {
  title: 'About',
  description: {
    paragraph_1: `Because you are in the best position to present your research and its results and because your time is precious, MyPubli offers you a new way of writing your medical or biomedical scientific publications.`,
    paragraph_2: `MyPubli supports you in achieving your publication objectives through its values: skills, collaboration and innovation.`,
    paragraph_3: `By collaborating, you join the MyPubli team.`
  },

  committee: {
    title: 'The Scientific and Ethics Committee',
    description: `The committee members are called upon for all ethical issues and to help MyPubli anticipate the changes of tomorrow.`
  },

  founders: {
    title: 'The founding members',
    description: `All share their knowledge and experience.`
  },

  members: {
    herve: {
      name: `Hervé Maisonneuve`,
      description: `Public health doctor, former director of evaluation at ANAES and consultant in scientific writing (www.redactionmedicale.fr).`
    },
    monique: {
      name: `Monique Rothan-Tondeur`,
      description: `Nurse, doctorate in public health, accreditation to direct research. Holder of the Research Chair in Nursing Sciences.`
    },
    bernard: {
      name: `Bernard Asselain`,
      description: `Biostatistician, former director of the biostatistics department at the Institut Curie and president of the French biometrics society.`
    },
    emmanuelle: {
      name: `Emmanuelle Kempf`,
      description: `Medical Oncologist, Assistant Head of Clinic.`
    },
    fabienne: {
      name: `Fabienne Péretz`,
      description: `Veterinary doctor. Virologist and medical writer. CEO of Abelia Science.`
    },
    martin: {
      name: `Martin Duracinsky`,
      description: `Clinician in Internal Medicine at the Bicêtre Hospital and Researcher in the PROs unit and at the URC-Eco of the Hôtel-Dieu Hospital in Paris.`
    },
    olivier: {
      name: `Olivier Chassany`,
      description: `Professor of medicine at the University of Paris, at INSERM and at the URC-Eco of the Hôtel-Dieu in Paris.`
    },
    alain: {
      name: `Alain Assouline`,
      description: `Founder and president of the WebForce3 school network and president of CINOV-Nu Numérique.`
    },
    michel: {
      name: `Michel Grivaux`,
      description: `Pulmonologist, former director of the clinical research center and former head of the pneumology department at the Meaux hospital`
    },
    cecile: {
      name: `Cécile Péretz`,
      description: `Engineer in computer science and financial mathematics. Business school student.`
    }
  }
}
