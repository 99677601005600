import form from './form'
import footer from './footer'

export default {
  form,
  footer,

  subscribe: 'SOUSCRIPTION',
  article: 'ARTICLE',
  payment: 'ACCES',
  confirm: 'CONFIRMATION',
  collaborators: 'CONTRIBUTEURS',

  newArticle: 'Créer un nouvel article',
  titleSelect: 'Sélectionnez votre offre',
  titleDetail: "Détail de l'offre",
  titleLayout: "Votre gabarit d'article",
  titlePayment: 'Méthode de payement',
  titleInvit: 'Inviter des contributeurs',

  validationError: {
    email: `L'adresse email saisie est invalide`
  }
}
