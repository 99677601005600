import layout from './layout.js'
import articles from './articles'
import create from './create'
import users from './users'
import homepage from './homepage'
import auth from './auth'
import resources from './resources'
import about from './about.js'
import faq from './faq.js'
import quoteRequest from './quoteRequest.js'
import talents from './talents'
import legals from './legals'
import partners from './partners'

export default {
  create,
  layout,
  articles,
  auth,
  homepage,
  resources,
  users,
  about,
  faq,
  quoteRequest,
  talents,
  legals,
  partners,
  show: 'Voir',
  handle: 'Gérer',
  save: 'Enregistrer',
  submit: 'Valider',
  delete: 'Supprimer',
  edit: 'Modifier',
  choose: 'Choisir',
  add: 'Ajouter',
  next: 'Suivant',
  cancel: 'Annuler',
  restrictedFiles:
    'Format de fichier non autorisé. Les formats acceptés pour ce type de documents sont : {filesAccepted}',
  success: 'Les données ont bien été enregistrées.',
  failed: `Une erreur est survenue, veuillez réessayer dans quelques instants.`
}
