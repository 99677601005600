import { Querier } from '@owliehq/querier'
import { $http } from 'boot/axios'

export class FaqRepository {
  static loadFaq() {
    const url = Querier.query({ baseUrl: `/faqarticles` })
      .sort('position')
      .generate()
    return $http.get(url)
  }
}
