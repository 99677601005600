import { CommentsRepository } from './repository'

export default {
  async LOAD_COMMENTS_BY_SECTIONIDS({ commit }, articleSectionIds) {
    const comments = await CommentsRepository.loadComments(articleSectionIds)
    commit('UPDATE_COMMENTS', comments)
    return comments
  },

  /**
   * add new section comment
   */
  async ADD_COMMENT({}, comment) {
    try {
      return await CommentsRepository.addComment(comment)
    } catch (err) {
      throw err
    }
  },

  /**
   * delete section comment
   */
  async DELETE_COMMENT({ commit }, commentId) {
    try {
      const deleteComment = await CommentsRepository.deleteComment(commentId)
      commit('REMOVE_COMMENT', commentId)
      return deleteComment
    } catch (err) {
      throw err
    }
  }
}
