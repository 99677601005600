export default {
  resources: {
    title: 'MyFormation',
    smallTitle: 'MyFormation: Access the latest tips and tricks in medical writing',
    description: 'Access the latest tips and tricks in medical writing.',
    link: 'Develop my skills'
  },
  tool: {
    title: 'MyCoach',
    smallTitle: 'MyCoach: Personalized support for your biomedical scientific writing',
    description: 'Personalized support for your biomedical scientific writing.',
    link: 'GET COACHED'
  },
  community: {
    title: 'MySolution',
    smallTitle: 'MySolution: Customized solutions to meet all your scientific writing needs',
    description: 'Customized solutions to meet all your scientific writing needs.',
    link: 'Find a solution'
  }
}
