export default {
  articles: [],
  versions: [],
  collaborations: [],
  articleFiles: [],
  currentVersion: null,
  historyVersion: null,
  article: null,
  pendingCollaborators: null,
  conflictsOfInterest: null,
  temporaryArticle: null,
  currentDocEditedSections: {},
  referencesDisplay: 'title',
  articleHasBeenUpdated: false
}
