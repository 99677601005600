export default {
  title: 'My articles',
  hint: 'Choose an article to consult it, edit it and access its dashboard.',
  add: 'New article',
  write: 'Write',
  empty: `You are not attached to any article for the moment.`,
  publish: `Article has been published.`,

  show: 'Show my articles',
  filters: {
    all: 'All articles',
    author: 'Articles I am an owner of',
    collaborator: 'Articles I am contributor / administrator of'
  },

  projects: '{nb} projects',

  card: {
    actions: {
      title: 'Actions',
      write: 'Write',
      preview: 'Preview',
      export: 'Export',
      invite: 'Invite contributors',
      addTalent: 'Add a service provider',
      end: 'Publish'
    },

    dashboard: 'Dashboard',
    wording: 'Write',

    status: {
      author: `I am owner`,
      admin: `I am administrator`,
      editor: `I am contributor`
    }
  }
}
