export default {
  unavailable: `L'édition d'article n'est pas disponible sur les petits écrans. Veuillez éditer votre article sur une tablette en format paysage ou un ordinateur`,
  history: 'Historique',
  historyLoadMore: 'Charger plus',
  preview: `Pré-visualiser l'article`,
  dashboard: 'Tableau de bord',
  articlelabel: 'Mes Articles',
  save: 'Sauvegarder',
  lastSave: 'Dernière sauvegarde à',
  versionDate: 'Sauvegarde du {date} à {time}',
  versionDateLight: '{date} à {time}',
  saveBeforeClose: 'Avez-vous bien sauvegardé vos modifications avant de fermer cette page ?',
  sidebarCard: {
    HELP: 'Aide et conseil',
    references: 'Références',
    COMMENTS: 'Commentaires',
    tables: 'Tables',
    figures: 'Figures',
    conflicts_of_interest: "Conflits d'intérêts",
    additional_tables: 'Tables supplémentaires',
    additional_figures: 'Figures supplémentaires',
    noFile: `Aucun document de ce type n'a été rattaché à cet article pour le moment.`,
    addUrlResource: 'Ajouter via une URL',
    uploadRessource: 'Ajouter via un fichier',
    uploadRis: 'Ajouter via un fichier RIS',
    uploadManually: 'Ajouter Manuellement',
    uploadFile: 'Ajouter',
    validate: 'Valider',
    cancel: 'Annuler',
    shapingHelp: 'Aide de mise en forme',
    gotchaDialog: 'Compris',
    shapingHelpDialogContent:
      'Voilà le shaping help dialog, qui nous dit comment bien former le contenu',
    referenceHint:
      'Saisissez ou copiez votre référence. Pensez à vérifier les aides de mise en forme.',

    contentTypesLabels: {
      article: 'Un article',
      book: 'Un livre',
      website: 'Un site',
      table: 'Une table',
      picture: 'Une image',
      other: 'Autre'
    },

    newRef: 'Nouvelle référence'
  },

  comments: {
    none: `Pas de commentaires enregistré sur cet élément pour le moment. Veuillez en créer un via l'éditeur.`,
    all: 'Tous les commentaires',
    infos: `Effectué par {author} sur {section}`,
    add: 'Laisser un commentaire',
    delete: {
      title: `Suppression d'un commentaire`,
      text: 'Cette action est irrévocable. Souhaitez-vous continuer ?'
    },
    saveWarning: "Attention: Ajouter un commentaire sauvegardera l'état actuel de votre article."
  },

  numberOfWords: '/ {numberOfWords} mots',

  updateTemplate: {
    title: `Votre version de gabarit n'est pas à jour`,
    text: `Vous pouvez rester sur votre version actuelle, ou le mettre à jour. Attention, certains éléments de votre article peuvent être effacés si vous choisissez de mettre à jour.`,
    action: 'Mettre à jour',
    cancel: 'Garder le gabarit actuel'
  },

  tasks: {
    delete: {
      title: `Suppression d'une tâche`,
      text: 'Cette action est irrévocable. Souhaitez-vous continuer ?'
    }
  },

  toolbars: {
    edit: {
      history: 'Historique des versions',
      undo: 'Revenir en arrière',
      redo: 'Revenir en avant',
      save: 'Sauvegarder la version'
    },

    visibility: {
      preview: 'Aperçu',
      export: 'Exporter'
    },

    infos: {
      stats: 'Avancement',
      abstract: `Voir l'abstract`,
      feedback: 'Tous les commentaires'
    },

    users: {
      add: 'Inviter',
      calendar: 'Calendrier'
    }
  },

  bloc: {
    help: `Aide et conseil`,
    bold: `Mettre en gras`,
    italic: `Mettre en italic`,
    strike: `Barrer le texte`,
    undo: `Annuler la dernière action`,
    redo: `Refaire la dernière action`,
    addComment: `Ajouter un commentaire`,
    showComments: `Afficher les commentaires`,
    red: `Mettre en rouge`,
    task: `Ajouter un jalon`,
    alertCollaborators: 'Envoyer une alerte aux contributeurs',
    sendAlert: 'Envoyer',
    alertCollaboratorsSuccess: 'Message envoyé avec succès',
    alertCollaboratorsError: 'Une erreur est survenue lors de l\'envoi du message',
    conflicts: "Conflits d'intérêts",
    references: 'Références',
    tables: 'Tables',
    figures: 'Figures',
    additional_tables: 'Tables supplémentaires',
    additional_figures: 'Figures supplémentaires'
  },

  specialBlocs: {
    addMultipleSingleText: 'Ajouter un champ'
  },

  referencesDisplay: {
    title: `titre`,
    author: `auteur`
  }
}
