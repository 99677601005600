import clone from 'clone'

export default {
  titleSections: state => {
    if (!state.currentVersion?.articleSectionSnapshots) return
    const sections = state.currentVersion.articleSectionSnapshots
    return sections.reduce((result, section) => {
      if (section.type === 'fixed_title') {
        result.push({
          label: section.typeOptions.text,
          key: section.key,
          orderWriting: section.orderWriting,
          children: []
        })
        return result
      } else if (section.type === 'text_bloc') {
        if (!result[result.length - 1])
          result.push({ label: 'Global', key: section.key, children: [] })
        if (result[result.length - 1]) {
          result[result.length - 1].children.push({
            orderWriting: section.orderWriting,
            label: section.label,
            key: section.key
          })
          result[result.length - 1].children.sort((a, b) => {
            a.orderWriting - b.orderWriting
          })
        }
      }
      result.sort((a, b) => a.orderWriting - b.orderWriting)
      return result
    }, [])
  },

  currentScope: (state, getters, rootState) => {
    if (!state.article?.collaborations) return
    const collaborations = state.article.collaborations
    const currentScope = collaborations.filter(collaboration => {
      if (
        collaboration.userId === rootState.auth.userId &&
        state.article.id === collaboration.articleId
      ) {
        return collaboration.scope
      }
    })
    if (currentScope.length) {
      return currentScope[0].scope
    } else {
      return null
    }
  },
  sectionsIds: state => {
    return state.currentVersion.articleSectionSnapshots.map(section => section.id)
  },
  currentDocEditedSectionsFiltered: state => {
    let toReturnSections = {}
    if (state.currentDocEditedSections) {
      let filtered = Object.values(state.currentDocEditedSections).filter(
        section => section.type !== 'statistics'
      )

      filtered.forEach(filteredItem => {
        toReturnSections[filteredItem.id] = filteredItem
      })
    }
    return toReturnSections
  }
}
