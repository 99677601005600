import { Querier } from '@owliehq/querier'
import { $http } from 'boot/axios'

export class ArticlesRepository {
  static async createArticle(payload) {
    const { title, templateId, templateAppliedId } = payload

    const article = await $http.post('/articles', { title })

    const articleVersion = await $http.post('/articleVersions', {
      articleId: article.id,
      templateId,
      templateAppliedId
    })

    return article.id
  }

  /**
   * load articles
   */

  static async loadArticles(offset) {
    try {
      const url = Querier.query({ baseUrl: `/articles` })
        .populate('lastArticleVersion.template')
        .populate('.lastArticleVersion.articleSectionSnapshots')
        .populate('.author')
        .populate('.collaborations.user')
        .sort('title')
        .limit(50)
        .skip(offset)
        .generate()
      const articles = await $http.get(url)
      return articles
    } catch (err) {
      throw err
    }
  }

  static loadCollaborations(id) {
    try {
      const url = Querier.query({ baseUrl: `collaborations` })
        .populate('tasks.assignedCollaborator.user')
        .populate('tasks.scopedArticleSection')
        .generate()

      return $http.get(url)
    } catch (err) {
      throw err
    }
  }

  static loadArticle(id) {
    try {
      const url = Querier.query({ baseUrl: `/articles/${id}` })
        .rawConditions({
          articleId: id
        })
        .populate('lastArticleVersion.template')
        .populate('lastArticleVersion.templateApplied')
        .populate('author')
        .populate('collaborations.user')
        .populate('articleFiles')
        .sort('orderWriting')
        .generate()

      return $http.get(url)
    } catch (err) {
      throw err
    }
  }

  static saveArticle(payload) {
    try {
      const { id } = payload
      if (!id) return $http.post(`/articles`, payload)
      return $http.put(`/articles/${id}`, payload)
    } catch (err) {
      throw err
    }
  }

  static loadConflictOfInterest(articleId) {
    const url = Querier.query({
      baseUrl: `/articles/${articleId}/conflictofinterest`
    }).generate()

    return $http.get(url)
  }

  static loadArticleFiles(articleId) {
    const url = Querier.query({ baseUrl: `/articleFiles` })
      .rawConditions({ articleId })
      .generate()

    return $http.get(url)
  }

  static async loadArticleVersions(params) {
    try {
      /*
              .populate(
          'articleSectionSnapshots.comments.authorCollaboration.user articleSectionSnapshots.comments.linkedSection'
        )
        */
      const url = Querier.query({ baseUrl: `/articleVersions` })
        .rawConditions({ articleId: params.id })
        .populate('template.lastTemplateSnapshot')
        .populate('articleSectionSnapshots')
        .sortDesc('lastSectionUpdatedAt')
        .limit(params.numberOfVersions)
        .generate()

      const versions = await $http.get(url)
      return versions
    } catch (err) {
      throw err
    }
  }

  static async loadArticleVersionsHistory(params) {
    try {
      if (!params.offset) {
        params.offset = 1
      }
      const url = Querier.query({ baseUrl: `/articleVersions` })
        .rawConditions({ articleId: params.id })
        .populate('articleSectionSnapshots')
        .sortDesc('lastSectionUpdatedAt')
        .limit(params.numberOfVersions)
        .skip(params.offset)
        .generate()

      const versions = await $http.get(url)
      return versions.filter(version => {
        if (version.articleId === parseInt(params.id)) return version
      })
    } catch (err) {
      throw err
    }
  }

  static async cloneVersion(payload) {
    const { version, updateTemplate, switchTemplate } = payload

    try {
      let { articleId, template, templateId, templateAppliedId } = version

      if (updateTemplate) templateAppliedId = template.lastTemplateSnapshotId

      const data = {
        articleId,
        templateId
      }

      if (!switchTemplate) data.templateAppliedId = templateAppliedId

      const { id } = await $http.post(`/articleversions`, data)

      const url = Querier.query({ baseUrl: `/articleVersions/${id}` })
        .populate('template.lastTemplateSnapshot')
        .populate(
          'articleSectionSnapshots.comments.authorCollaboration.user articleSectionSnapshots.comments.linkedSection'
        )
        .generate()

      return $http.get(url)
    } catch (err) {
      throw err
    }
  }

  static async saveSection(section) {
    try {
      const { id } = section
      return await $http.put(`/articlesectionsnapshots/${id}`, section)
    } catch (err) {
      throw err
    }
  }

  static async saveMultipleSection(payload) {
    try {
      const { sections, articleId } = payload
      let finalSections = Object.values(sections)
      return await $http.post(`/articleversions/createwithsections`, {
        sections: finalSections,
        articleId: articleId
      })
    } catch (err) {
      throw err
    }
  }

  static async createNewArticleVersion(article) {
    try {
      return await $http.post('/articleversions', {
        articleId: article.articleId,
        templateId: article.templateId
      })
    } catch (err) {
      console.err(err)
      throw err
    }
  }

  static async getSection(sectionId) {
    try {
      return await $http.get(`/articlesectionsnapshots/${sectionId}/lastversion`)
    } catch (err) {
      throw err
    }
  }

  static addComment(comment) {
    try {
      return $http.post('/comments', comment)
    } catch (err) {
      throw err
    }
  }

  static deleteComment(commentId) {
    try {
      return $http.delete(`/comments/${commentId}`)
    } catch (err) {
      throw err
    }
  }

  static deleteCollaboration(collaborationId) {
    try {
      return $http.delete(`/collaborations/${collaborationId}`)
    } catch (err) {
      throw err
    }
  }

  static updateCollaboration(collaborationId, payload) {
    try {
      return $http.put(`/collaborations/${collaborationId}`, payload)
    } catch (err) {
      throw err
    }
  }

  static sendCollaborationInvitations(payload) {
    const { emails, userId, articleId } = payload
    try {
      return $http.post(`/collaborations/invitations`, { emails, userId, articleId })
    } catch (err) {
      throw err
    }
  }

  static async inviteCollaborator(payload) {
    const { email, scope, articleId } = payload
    try {
      // return {code: 2, invitation} if userInvitation is created
      // return {code: 1, collaboration} if collaboration is created
      return await $http.post('/collaborations/invitation', { email, scope, articleId })
    } catch (error) {
      return { code: 0 }
    }
  }

  static async getPendingInvitations() {
    try {
      return $http.get(`/userInvitations/`)
    } catch (error) {
      return error
    }
  }

  static exportArticle(articleId) {
    return $http.get(`/articles/${articleId}/generate`, { responseType: 'blob' })
  }

  static async endArticle(articleId) {
    try {
      return await $http.put(`/articles/${articleId}`, { state: 'published' })
    } catch (err) {
      console.error('repo: endArticle error ' + JSON.stringify(err))
      throw err
    }
  }
}
