import { ResourcesRepository } from './repository'

export default {
  async LOAD_THEMES({ commit }) {
    const themes = await ResourcesRepository.loadThemes()
    commit('SET_THEMES', themes)
  },

  async LOAD_CATEGORIES({ commit }) {
    const categories = await ResourcesRepository.loadCategories()
    commit('SET_CATEGORIES', categories)
  },

  async LOAD_RESOURCES_TYPES({ commit }) {
    const resourceTypes = await ResourcesRepository.loadResourcesTypes()
    commit('SET_RESOURCES_TYPES', resourceTypes)
  },

  async LOAD_RESOURCES({ commit }, categoryId) {
    const resources = await ResourcesRepository.loadResources(categoryId)
    for (const resource of resources) {
      if (resource.type === 'picture' && resource.content.type === 'upload' && resource.content.id)
        resource.content.file = await ResourcesRepository.loadResourceFile(resource.content.id)
      if (resource.type === 'pdf' && resource.content.type === 'upload' && resource.content.id)
        resource.content.file = await ResourcesRepository.loadResourceFile(resource.content.id)
    }
    commit('SET_RESOURCES', resources)
  },

  async LOAD_LAST_RESOURCES({ commit }) {
    const resources = await ResourcesRepository.loadLastResources()
    commit('SET_LAST_RESOURCES', resources)
  },

  async LOAD_SEARCH_RESOURCES({ commit }, keyword) {
    if (!keyword) {
      commit('SET_SEARCH_RESOURCES', [])
      return
    }
    const resources = await ResourcesRepository.searchResources(keyword)
    commit('SET_SEARCH_RESOURCES', resources)
  },

  async SAVE_CATEGORY({}, category) {
    await ResourcesRepository.saveCategory(category)
  },

  async SAVE_RESOURCE({}, resource) {
    await ResourcesRepository.saveResource(resource)
  },

  async UPLOAD_RESOURCE_FILE({}, payload) {
    return ResourcesRepository.createResourceFile(payload)
  }
}
