export default {
  title: 'Réinitialisation du mot de passe',
  message: 'Veuillez saisir un nouveau mot de passe',
  form: {
    email: 'Email',
    password: 'Mot de passe'
  },
  passwordRules: '8 caractères avec 1 majuscule minimum',
  submit: 'Confirmer',
  confirmation: {
    message1: 'Votre mot de passe à bien été mis à jour',
    message2: 'Vous pouvez vous connecter',
    submit: "S'identifier"
  },
  emailSent: `Un email de réinitialisation vous a été envoyé. Veuillez cliquer sur le lien présent dans le mail pour mettre à jour votre mot de passe.`
}
