import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'
import { decodeToken } from 'src/utils/tokens'
import auth from 'src/i18n/fr/auth'

Vue.use(VueRouter)

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default function({ store }) {
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  })

  Router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const token = localStorage.getItem('refreshToken')
    try {
      const { userId } = decodeToken(token)
      store.commit('auth/SET_USER_ID', userId)
    } catch (error) {
      console.log(error)
    }
    const savedRouteName = store.getters['auth/savedRouteName']
    const logoutAsked = localStorage.getItem('logoutAsked')
    if (to.name === 'login' && !token && !logoutAsked) {
      store.commit('auth/SET_SAVED_ROUTE_NAME', from.name)
    } else if (to.name === 'home' && savedRouteName) {
      store.commit('auth/SET_SAVED_ROUTE_NAME', null)
      return next({ name: savedRouteName })
    }

    if (to.name == 'login' && token) return next('/')
    if (!requiresAuth) return next()
    if (requiresAuth && !token) return next('/auth')

    return next()
  })
  return Router
}
