import { $http } from 'boot/axios'
import { Querier } from '@owliehq/querier'

export class TasksRepository {
  static loadTasksBySectionIds(articleSectionIds) {
    try {
      let conditions
      if (typeof articleSectionIds === 'number') {
        conditions = { scopedArticleSectionId: articleSectionIds }
      } else {
        conditions = { scopedArticleSectionId: { $or: articleSectionIds } }
      }
      const url = Querier.query({ baseUrl: `/tasks/` })
        .populate('assignedCollaborator.user creator.user scopedArticleSection')
        .rawConditions(conditions)
        .sortDesc('id')
        .generate()

      return $http.get(url)
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  static loadTasksByTasksIds(tasksIds) {
    try {
      let conditions
      if (typeof tasksIds === 'number') {
        conditions = { id: tasksIds }
      } else {
        conditions = { id: { $or: tasksIds } }
      }
      const url = Querier.query({ baseUrl: `/tasks/` })
        .populate('assignedCollaborator.user creator.user scopedArticleSection')
        .rawConditions(conditions)
        .sortDesc('id')
        .generate()

      return $http.get(url)
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  static updateTask(task) {
    const { id } = task
    if (!id) return $http.post('/tasks', task)

    return $http.put(`/tasks/${id}`, task)
  }

  static deleteTask(id) {
    return $http.delete(`/tasks/${id}`)
  }
}
