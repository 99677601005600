export default {
  home: 'Home',
  resources: 'Resources',
  offers: 'Our Offers',
  mycoach: 'MyCoach',
  myformation: 'MyFormation',
  mysolutions: 'MySolution',
  talents: {
    title: 'Providers',
    join: 'Join the community of providers',
    find: 'Find a provider'
  },
  tools: 'Tools',
  tool: 'Tool',
  partners: 'Partners',
  news: 'News',
  about: 'About',
  faq: 'FAQ',
  welcome: 'Welcome',
  login: 'Log in',

  profile: {
    dashboard: 'My Articles',
    title: 'My Profile',
    logout: 'Logout'
  },

  footer: {
    contact: 'Contact Us',
    cgu: 'Terms and Conditions',
    mentions: 'Legal Mentions',
    personalData: 'Personal Data',
    help: 'Need Help?',
    copyright: '© 2021. All rights reserved.',
    communityTitle: 'Communauty',
    communityDescription: 'You are a professional and you want to join our community of service providers to offer your services to our customers?',
    menu: {
      home: 'Menu',
      offers: 'Our Offers',
      talents: 'Partners',
      about: 'About',
      faq: 'FAQ',
    }
  },
}
