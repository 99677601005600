export default {
  resources: 'Ressources et astuces',
  linkCopied: 'Le lien de partage a été copié dans votre presse-papier.',
  search: {
    searchResult: 'Rechercher une ressource',
    keywords: 'Mots clefs',
    results: 'résultats',
    noResult: "Désolé ! Nous n'avons pas trouvé de résultat à votre recherche",
    noResult2:
      'Notre base de ressources est trés régulièrement alimentée et mise à jour, peut-être une prochaine fois ?'
  },

  list: {
    noCategories: 'Aucune catégorie',
    noResources: 'Aucune ressource',
    nextPage: 'Page suivante',
    previousPage: 'Page précédente',
    submitResource: {
      message1: 'Vous souhaitez participer à la création de ces ressources ?',
      message2: 'Envoyez-nous votre ressource ci-dessous',
      button: 'Contribuer aux ressources'
    }
  },

  submitPage: {
    header: 'Soumettre une ressource',
    theme: 'Choisir le thème',
    category: 'Choisir la catégorie',
    otherCategory: '(Aucune catégorie ne correspond)',
    type: 'Quel type de ressource souhaitez-vous soumettre ?',
    image: 'Un visuel',
    pdf: 'Un PDF',
    video: 'Une vidéo',
    text: 'Un texte',
    or: 'ou',
    link: 'Un lien vers une autre page',
    uploadImage: 'Télécharger votre visuel',
    uploadPDF: 'Télécharger votre PDF',
    url: 'Lien URL',
    textLabel: 'Texte',
    title: 'Titre de votre ressource',
    hideAuthor:
      "en cliquant sur cette case, votre nom et prénom n'apparaîtront pas sur la ressource",
    send: 'Envoyer'
  },
  submitValidation: {
    title: 'Merci pour votre envoi !',
    success: 'Votre ressource a bien été envoyée',
    validation: 'Après validation par notre équipe, nous la mettrons à disposition en ligne',
    mail: 'Vous serez prévenu par email de la validation de votre ressource',
    continue: 'Continuer'
  },
  formation: {
    title: 'Formation',
    description:'Vous souhaitez vous former ou former vos collaborateurs ?',
    askQuote: 'Demander un devis',

  },
  collaboration: {
    title: 'Collaboration',
    description:'Vous souhaitez participer à la création de ces ressources ?',
    contribute: 'Contribuer aux ressources',
  },
}
