export default {
  title: "Login",

  form: {
    email: 'Email',
    password: 'Password'
  },
  forgottenPassword: 'Forgotten password',
  submit: "Log in",

  error: {
    email: `Email address is not valid`,
    password: 'Must contain 8 characters with 1 uppercase minimum',
    invalid: 'Invalid credentials or not recognized.',
    inactive: `This account has not been validated`
  }
}
