export default {
  title: "S'inscrire",
  subtitle: 'Créez votre compte utilisateur pour commencer à rédiger votre article',
  form: {
    email: 'Email',
    password: 'Mot de passe',
    confirmPassword: 'Confirmer le mot de passe',
    firstName: 'Prénom',
    lastName: 'Nom',
    profession: 'Type de compte'
  },
  error: {
    profession: 'Le type de compte est requis.',
    email: `L'adresse email est incorrecte.`,
    password: 'Doit contenir 8 caractères avec 1 majuscule minimum',
    confirmPassword: 'Les mots de passe de sont pas identiques.',
    firstName: 'Le prénom est requis.',
    lastName: 'Le nom est requis.'
  },
  passwordRules: '8 caractères avec 1 majuscule minimum',
  submit: "S'inscrire"
}
