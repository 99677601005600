import { TasksRepository } from './repository'

export default {
  async LOAD_TASKS_BY_SECTIONIDS({ commit }, articleSectionIds) {
    const tasks = await TasksRepository.loadTasksBySectionIds(articleSectionIds)
    commit('UPDATE_TASKS', tasks)
  },

  /**
   *

   * @returns
   */
  async UPDATE_TASK({ commit }, taskPayload) {
    const dbTask = await TasksRepository.updateTask(taskPayload)
    const task = await TasksRepository.loadTasksByTasksIds(dbTask.id)
    commit('UPDATE_TASKS', task)
  },

  /**
   * delete one task
   */
  async DELETE_TASK({ commit }, task) {
    const { id } = task
    try {
      await TasksRepository.deleteTask(id)
      commit('REMOVE_TASK', id)
      return
    } catch (err) {
      throw err
    }
  }
}
