export default {
  buttons: {
    back: 'retour',
    next: 'suivant'
  },
  article: {
    articleTitle: {
      title: 'Titre de votre projet',
      hint: 'Choisissez le titre de votre projet'
    },

    type: {
      title: "Type d'article",
      hint: "Choisissez le type d'article souhaité"
    },

    template: {
      title: 'Choisissez la revue',
      hint: 'Choisisez la revue souhaitée '
    },

    templateOnEssential: {
      title: 'Choisissez le type de revue',
      hint: 'Choisissez le type de revue souhaitée'
    },

    lang: {
      title: 'Langue de rédaction',
      hint: 'Choisissez la langue de rédaction'
    },

    payment: {
      method: `Méthode de paiement`,
      card: `Carte`,
      coupon: `Coupon`,
      discountCoupon: `Coupon de réduction`,
      discountCouponHint: `Entrez un coupon de réduction`,
      payWithCoupon: `Payer avec le coupon`,
      checkCoupon: `Utiliser un coupon`,
      validateCoupon: `Valider le coupon`,
      noCoupon: `Vous n'avez pas de coupon ?`,
      amount: `Prix de l'article`,
      discount: `Réduction avec le coupon`,
      total: `Montant à payer`,
      couponField: `Utiliser un coupon`,
      couponHint: `Utiliser un coupon fourni par MyPubli`,
      informations: `Informations`,
      showPaymentForm: 'Payer votre article',
      secure: 'Paiement simple et sécurisé',
      pay: 'Payer ({price})',
      title: 'Finalisez votre commande',
      subtitle: 'Votre carte de paiement',
      failed: 'Erreur inconnue. Veuillez contacter votre banque ou essayer une autre carte. ',
      confirmation: `Le paiement a été accepté.`,
      quotationError: `Ce coupon est invalide ou ne permet pas de payer ce type d'article`,
      couponInvalid: `Ce coupon est invalide`,
      couponExpired: `Ce coupon est expiré`,
      noUsageLeft: `Ce coupon ne peut plus être utilisé`,
      useCoupon: `Valider l'utilisation du coupon`
    },

    created: 'Votre article a bien été créé.'
  },

  askNewArticle: {
    publicationName: {
      title: 'Revue de publication souhaitée',
      hint: 'Saisissez le nom de la revue'
    },
    type: {
      title: "Type d'article souhaité",
      hint: "Saisissez le type d'article"
    },
    lang: {
      title: 'Langue de publication',
      hint: 'Saisissez la langue de publication'
    }
  },

  templateInfo: {
    t1: 'Demande de création de gabarit',
    t2:
      "MyPubli créera le gabarit adapté à votre demande sans impact sur le tarif de l'offre choisie.",
    t3:
      "Vous pouvez finaliser votre commande et nous préviendrons par email lorsque votre gabarit d'article sera prêt et disponible depuis votre espace de travail",
    t4: 'Le délai de création est de 72h maximum'
  },

  offers: {
    student: 'Étudiant',
    standard: 'Standard',
    professional: 'Professionnel',
    company: 'Entreprise',
    pack: 'Pack'
  },

  priceVAT: 'TTC',

  offerDetails: {
    change: 'Changer',

    1: "Gabarit d'article adapté aux lignes directives de votre choix",
    2: "Rédaction collaborative (jusqu'à 12 contributeurs)",
    3: 'Choix de langue de rédaction : français ou anglais',
    4: 'Suivi des modifications',
    5: 'Bibliographie automatique',
    6: 'Conseils personnalisés',
    7: 'Espace de stockage partagé',
    8: 'Astuces accessibles tout au long de la rédaction',
    9: 'Utilisation illimitée'
  },

  confirmed: {
    c1: 'Votre Paiment a bien été accepté',
    c2:
      "Vous pouvez commencer à inviter vos contributeurs et profiter de l'outil de rédaction dès maintenant",
    c3: 'INVITER DES CONTRIBUTEURS'
  },
  // SECTION 3
  submitAsk: "Je ne trouve pas le type d'article que je souhaite rédiger?",
  submit: 'Soumettre une demande',
  unSubmit: 'Je ne souhaite pas soumettre une demande',
  //SECTION 4
  seeTemplates: 'Voir les gabarits existants',
  // SECTION 7
  enterAdress: ' Saisissez les adresses mails des personnes que vous souhaitez inviter:',
  emailLabel: 'nomprenom@email.com',
  emailSelect: {
    editor: 'Editeur',
    admin: 'Administrateur',
  },
  send: 'ENVOYER ET REDIGER',
  later: 'Je le ferai plus tard',
  validationError: {
    email: 'Veuillez entrer un email valide'
  }
}
