import actions from './actions'
import state from './state'

import { make } from 'vuex-pathify'

export default {
  namespaced: true,

  getters: {
    ...make.getters(state)
  },

  mutations: {
    ...make.mutations(state)
  },

  actions,
  state
}
