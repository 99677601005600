export default {
  welcome: 'Bienvenue',
  form: {
    avatar: 'Avatar',
    email: 'Email',
    firstName: 'Prénom',
    lastName: 'Nom',
    currentPwd: 'Mot de passe actuel',
    newPwd: 'Nouveau mot de passe',
    confirmPwd: 'Confirmation',
    professional: 'Profil professionnel',
    degree: 'Diplômes',
    affiliation: 'Affiliations',
    conflict: `Conflit d'intérêt`,
    viewCurrentConflictOfInterestFile: `Voir mon document actuel`,
    conflictFileHint: `Sélectionnez votre document conflit d'intérêt (.pdf)`,
    conflitsGuideUrl: `http://icmje.org/disclosure-of-interest/`,
    password: 'Mot de passe',
    changePassword: 'Changer de mot de passe',
    submit: 'Enregistrer',
    confirm: 'Vos modifications ont bien été pris en compte',
    error: {
      required: 'Le champ est requis',
      password: 'Doit contenir 8 caractères avec 1 majuscule minimum',
      confirm: 'Un ou plusieurs champs sont incorrects',
      sameAs: `Le mot de passe n'est pas identique`,
      email: `L'adresse email est incorrecte`
    }
  }
}
