export default {
  offerTitle: 'Nos offres',
  offerDescription: 'Fournissons ensemble à votre équipe les outils nécessaires pour avancer avec confiance vers vos objectifs scientifiques !',
  offer1: {
    name: 'essential',
    label: 'Essentielle',
    price: '174 €',
    amount: '17400'
  },
  offer2: {
    name: 'adapted',
    label: 'Adaptée',
    price: '327 €',
    amount: '32700'
  },
  quote1: {
    name: 'custom',
    label: 'Personnalisée',
    description: 'Sur devis ou bon de commande'
  },
  quote2: {
    name: 'multilicences',
    label: 'Multi-Licences',
    description: 'Sur devis ou bon de commande'
  },
  taxes: 'TTC',
  choiceBtn: 'Choisir cette offre',
  quoteBtn: 'Demander un devis',

  feature1: "Gabarit d'article adapté",
  feature2: "Rédaction collaborative (jusqu'à 12 contributeurs)",
  feature3: 'Choix de langue de rédaction: français ou anglais',
  feature4: 'Suivi des modifications',
  feature5: 'Bibliographie automatique',
  feature6: 'Conseils personnalisés',
  feature7: 'Espace de stockage partagé',
  feature8: 'Ressources accessibles tout au long de la rédaction',

  detailsBtn: "Découvrir l'outil",

  advantage1: 'Plateforme sécurisée',
  advantage2: 'Suivi et conseils',
  advantage3: `Indépendant et libre de tout conflit d'intérêt`,
  advantage4: 'Développé par des experts',
  advantage5: 'Ouvert à toutes les spécialités médicales'
}
