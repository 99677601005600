import progression from './progression'
import account from './account'

export default {
  progression,
  account,

  professions: {
    student: 'Etudiant',
    regular: 'Compte courant'
  }
}
