export default {
  assignedTo: 'Assigned to',
  on: 'On',
  invite: 'Invite',

  menu: {
    dashboard: 'My articles',
    calendar: 'Calendar',
    template: `Article template`,
    files: 'Documents',
    collaborators: 'Contributors',
    subscription: 'Subscription'
  },

  infos: {
    showCalendar: 'See in calendar',
    inProgress: '{nb} in progress',
    done: '{nb} done',
    progression: `Progress status`,
    deadline: 'Deadline',
    noDeadline: 'No deadline',
    tasks: 'Tasks',
    nextMilestone: 'Next milestone',
    currentTemplate: 'Chosen template',
    choosenTemplate: 'Chosen journal',
    compatibleGuidelines: 'Compatible guidelines',
    lang: 'Language',
    noTask: 'No task',
    noMilestone: 'No milestone',
    lastUpdate: `Added on {date} at {time}`,
    author: 'Administrator'
  },

  template: {
    select: 'Name of chosen journal',
    url: `Url of journal's guidelines`,
    guidelines: 'Guidelines: ',
    guidelinesHint: 'You are writing an article compliant with the following checklist: ',
    lang: 'Article language: ',
    confirmation: `Your template request for the journal "{title}" has been taken into account`,
    ask: 'You dont find your template? Contact us',
    switch: {
      title: 'Template change',
      text: `Warning, some parts of your article may be lost if you change template`
    }
  },

  files: {
    conflicts_of_interest: `Conflicts of interest`,
    references: 'References',
    tables: 'Tables',
    figures: 'Figures',
    additional_tables: 'Supplementary tables',
    additional_figures: 'Supplementary figures',
    others: 'Other documents',
    hint: `Upload or drop your {element} in this section. They will be attached to your article`,
    downloadHint: `Click here to download your file {type}`,
    downloadInscriptionHint: `Upload your student card ({type})`,
    delete: {
      title: `Deleting of document`,
      text: 'This action is definitive. Do you wish to proceed?'
    },
    referencesDisplay: {
      title: `Display by title`,
      author: `Display par author`
    }
  },

  collaborations: {
    scope: {
      owner: 'Owner',
      admin: 'Administrator',
      editor: 'Editor',
      reader: 'Reviewer'
    },
    invited: 'pending',
    delete: {
      title: `Deleting of contributors`,
      text: 'This action is definitive. Do you wish to proceed?'
    },

    create: {
      title: 'Invite contributors',
      text: 'Type in the email addresses of the people you wish to invite: '
    },

    authorOnly: `Only the author of the article is allowed to perform this action`,
    invitationsSuccess: `An email was sent to every contributor. Unregistered contributors will automatically be added once they have registered `
  }
}
