export default {
  partners: [
    {
      name: 'Nordic Pharma',
      logo: require('src/assets/partners/1-nordic-pharma.png'),
      url_link: 'https://www.nordicpharma.com/'
    },
    {
      name: 'BPI',
      logo: require('src/assets/partners/2-bpi.png'),
      url_link: 'https://www.bpi.com/'
    },
    {
      name: 'Manufactory',
      logo: require('src/assets/partners/3-manufactory.jpg'),
      url_link: 'https://www.manufactory.com/'
    },
    {
      name: 'AER-BFC',
      logo: require('src/assets/partners/4-aer-bfc.jpg'),
      url_link: 'https://aer-bfc.com/'
    },
    {
      name: 'Frenchtech',
      logo: require('src/assets/partners/5-frenchtech.png'),
      url_link: 'https://lafrenchtech.com/fr/'
    },
    {
      name: 'EM Lyon',
      logo: require('src/assets/partners/6-emlyon.jpg'),
      url_link: 'https://www.em-lyon.com/'
    },
    {
      name: 'ISNI',
      logo: require('src/assets/partners/7-logoisni.png'),
      url_link: 'https://www.isni.fr/'
    },
  ]
}
