import state from './state'

export default {
  SET_TYPE: (state, value) => {
      state.offer.type = value
  },
  SET_SELECTEDOFFER: (state, value) => {
    state.selectedOffer = value
  },
  SET_MISSINGTEMPLATE: (state, value) => {
    state.missingTemplate = value
  }
}
