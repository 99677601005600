import { MailsRepository } from './repository'

export default {
  async SEND_CONTACT_MAIL({}, payload) {
    await MailsRepository.sendContactMail(payload)
  },
  async SEND_REMINDER_MAIL({}, payload) {
    await MailsRepository.sendReminderMail(payload)
  }
}
