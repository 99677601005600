export default {
  title: `Je souhaite être accompagné par un prestataire`,
  introduction: `insérer un texte d'introduction`,
  informations: {
    title: `Informations personnelles`,
    lastname: `Nom`,
    firstname: `Prénom`,
    email: `Adresse Email`,
    phone: `Numéro de téléphone`
  },
  request: {
    title: `Votre demande`,
    type: `Choisissez votre type de livrable`,
    speciality: `Choisissez votre spécialité demandée`,
    text: `Champ de saisie`
  },
  submit: 'Envoyer'
}
