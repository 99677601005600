export default {
  resources: {
    title: 'MyFormation',
    smallTitle: 'MyFormation : accédez aux conseils et astuces en rédaction médicale les plus récents',
    description:
      'Accédez aux conseils et astuces en rédaction médicale les plus récents.',
    link: 'Développer mes compétences'
  },
  tool: {
    title: 'MyCoach',
    smallTitle: 'MyCoach : un accompagnement personnalisé pour votre rédaction scientifique biomédicale',
    description: 'Un accompagnement personnalisé pour votre rédaction scientifique biomédicale',
    link: "ÊTRE COACHÉ.E"
  },
  community: {
    title: 'MySolution',
    smallTitle: 'MySolution : des solutions personnalisées pour répondre à tous vos besoins de rédaction scientifique ',
    description:
      'Des solutions personnalisées pour répondre à tous vos besoins de rédaction scientifique.',
    link: 'Trouver une solution'
  }
}
