import { $http } from 'boot/axios'
import { Querier } from '@owliehq/querier'

export class CommentsRepository {
  static loadComments(articleSectionIds) {
    try {
      let conditions
      if (typeof articleSectionIds === 'number') {
        conditions = { linkedSectionId: articleSectionIds }
      } else {
        conditions = { linkedSectionId: { $or: articleSectionIds } }
      }
      const url = Querier.query({ baseUrl: `/comments/` })
        .populate('authorCollaboration.user')
        .populate('linkedSection')
        .rawConditions(conditions)
        .sortDesc('id')
        .generate()

      return $http.get(url)
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  static updateComment(comment) {
    const { id } = comment
    if (!id) return $http.post('/comment', comment)

    return $http.put(`/comment/${id}`, comment)
  }

  static addComment(comment) {
    try {
      return $http.post('/comments', comment)
    } catch (err) {
      throw err
    }
  }

  static deleteComment(commentId) {
    try {
      return $http.delete(`/comments/${commentId}`)
    } catch (err) {
      throw err
    }
  }
}
