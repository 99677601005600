export default {
  title: 'I wish to be accompanied by a service provider',
  introduction: 'insert an introduction text',
  informations: {
    title: 'Personal information',
    lastname: 'Last name',
    firstname: 'First name',
    email: 'Email address',
    phone: 'Phone number'
  },
  request: {
    title: 'Your request',
    type: 'Choose your type of deliverable',
    speciality: 'Choose your requested specialty',
    text: 'Input field'
  },
  submit: 'Send'
}
