import { Querier } from '@owliehq/querier'
import { $http } from 'boot/axios'

export class ResourcesRepository {
  static loadThemes() {
    const url = Querier.query({ baseUrl: '/resourceCategories' })
      .populate('children')
      .rawConditions({
        parentId: null
      })
      .sort('title')
      .generate()
    return $http.get(url)
  }

  /**
   *
   *
   */
  static loadCategories() {
    return $http.get('/resourceCategories')
  }

  /**
   *
   *
   */
  static loadResourcesTypes() {
    return $http.get('/enums/resources/types')
  }

  /**
   *
   *
   */
  static loadResources(categoryId) {
    const url = Querier.query({ baseUrl: '/resources' })
      .rawConditions({
        categoryId: categoryId,
        valid: true
      })
      .populate('author')
      .populate('category')
      .sort('position')
      .generate()

    return $http.get(url)
  }

  /**
   *
   *
   */
  static async loadResourceFile(id) {
    try {
      return $http.get(`/resourceFiles/${id}`)
    } catch (err) {}
  }

  /**
   *
   *
   */
  static loadLastResources() {
    const url = Querier.query({ baseUrl: '/resources' })
      .rawConditions({
        valid: true
      })
      .sortDesc('id')
      .limit(3)
      .populate('author')
      .populate('category')
      .generate()

    return $http.get(url)
  }

  /**
   *
   *
   */
  static searchResources(keyword) {
    return $http.post('/resources/search', { search: keyword })
  }

  /**
   *
   *
   */
  static saveCategory(category) {
    const { id } = category
    if (!id) return $http.post('/resourceCategories', category)

    return $http.put(`/resourceCategories/${id}`, category)
  }

  /**
   *
   *
   */
  static saveResource(resource) {
    const { id } = resource
    if (!id) return $http.post('/resources', resource)

    return $http.put(`/resources/${id}`, resource)
  }

  /**
   * Create resource file
   * @param {} payload
   */
  static async createResourceFile(payload) {
    let fd = new FormData()
    fd.append('key', payload.key)
    fd.append('name', payload.name)

    return $http.post(`/resourceFiles`, fd)
    //return $http.post(`/resourceFiles`, fd, {
    //  headers: { 'Content-Type': 'multipart/form-data' }
    //})
  }
}
