export default {
  buttons: {
    back: 'back',
    next: 'next',
  },
  article: {
    articleTitle: {
      title: 'Title of your article',
      hint: 'Choose a title for your article'
    },

    type: {
      title: "Article type",
      hint: "Choose the type of article you want to write"
    },

    template: {
      title: 'Choose the publication',
      hint: 'Choose the publication you want to write for'
    },

    templateOnEssential: {
      title: 'Choose publication type',
      hint: 'Choose the type of publication you want to write for'
    },

    lang: {
      title: 'Article language',
      hint: 'Choose the article language:'
    },

    payment: {
      method: `Payment method`,
      card: `Card`,
      coupon: `Coupon`,
      discountCoupon: `Discount coupon`,
      discountCouponHint: `Use a coupon provided by MyPubli`,
      payWithCoupon: `Pay with coupon`,
      checkCoupon: `Use a coupon`,
      validateCoupon: `Validate your coupon`,
      noCoupon: `You don't have a coupon?`,
      amount: `Article price`,
      discount: `Reduction with coupon`,
      total: `Amount to pay`,
      couponField: `Use a coupon`,
      couponHint: `Use a coupon provided by MyPubli`,
      informations: `Informations`,
      showPaymentForm: 'Pay your article',
      secure: 'Secure and easy payment',
      pay: 'Pay ({price})',
      title: 'Finalize your payment',
      subtitle: 'Your credit card',
      failed: 'Unknow error. Please try again later or contact your bank provider.',
      confirmation: `Payment has been confirmed`,
      quotationError: `This coupon is not valid or not available for this quotation`,
      couponInvalid: `This coupon is not valid`,
      couponExpired: `This coupon is expired`,
      noUsageLeft: `This coupon has no usage left`,
      useCoupon: `Validate your coupon`,
    },

    created: 'Your article has been created',
  },

  askNewArticle: {
    publicationName: {
      title: 'Publication wanted',
      hint: 'Write publication name'
    },
    type: {
      title: "Article type wanted",
      hint: "Write article type"
    },
    lang: {
      title: 'Publication language',
      hint: 'Write publication language'
    }
  },

  templateInfo: {
    t1: 'Ask for a custom template',
    t2:
      "MyPubli will create a template for you and you will be able to write your article directly from your workspace",
    t3:
      "You can finalize your order and we will notify by email when your article template is ready and available from your workspace",
    t4: 'The creation time is 72 hours maximum'
  },

  offers: {
    student: 'Student',
    standard: 'Standard',
    professional: 'Professional',
    company: 'Company',
    pack: 'Pack'
  },

  priceVAT: 'Including VAT',

  offerDetails: {
    change: 'Change',

    1: "Article template adapted to the guidelines of your choice",
    2: "Collaborative writing (up to 12 contributors)",
    3: 'Choice of article language: French or English',
    4: 'Tracked changes',
    5: 'Automatic bibliography',
    6: 'Personalized advice',
    7: 'Shared storage space',
    8: 'Tips accessible throughout the writing process',
    9: 'Unlimited use'
  },

  confirmed: {
    c1: 'Your payment has been accepted',
    c2:
      "You can start inviting your contributors and take advantage of the writing tool now",
    c3: 'INVITE CONTRIBUTORS'
  },
  // SECTION 3
  submitAsk: "I can't find the type of article I want to write?",
  submit: 'Submit a request',
  unSubmit: 'I do not wish to submit a request',
  //SECTION 4
  seeTemplates: 'View existing templates',
  // SECTION 7
  enterAdress: 'Enter the email addresses of the people you want to invite:',
  emailLabel: 'name@email.com',
  emailSelect: {
    editor: 'Editor',
    admin: 'Administrator',
  },
  send: 'SEND AND WRITE',
  later: 'I will do it later',
  validationError: {
    email: 'Please enter a valid email'
  }
}
