export default {
  home: 'Accueil',
  resources: 'Ressources',
  offers: 'Nos offres',
  mycoach: 'MyCoach',
  myformation: 'MyFormation',
  mysolutions: 'MySolution',
  talents: {
    title: 'Prestataires',
    join: 'Rejoindre la communauté des prestataires',
    find: 'Trouver un prestataire'
  },
  tools: 'Outils',
  tool: 'Outil',
  partners: 'Partenaires',
  news: 'Actualités',
  about: 'A propos',
  faq: 'FAQ',
  welcome: 'Bienvenue',
  login: 'Se connecter',

  profile: {
    dashboard: 'Mes articles',
    title: 'Mon profil',
    logout: 'Déconnexion'
  },

  footer: {
    contact: 'Contactez-nous',
    cgu: `CGU / CGV`,
    mentions: 'Mentions légales',
    personalData: 'Données personnelles',
    help: 'Besoin d’aide ?',
    copyright: '© 2021. Tous droits réservés.',
    communityTitle: 'Communauté',
    communityDescription: 'Vous êtes un professionnel et vous souhaitez rejoindre notre communauté de prestataires pour proposer vos services à nos clients ?',
    menu: {
      home: 'Menu',
      offers: 'Nos offres',
      talents: 'Partenaires',
      about: 'A propos',
      faq: 'FAQ',
    }
  },
}
