
export default {
  zotero: {
    loadParser: `Load from URL`,
    parserError: `An error occurred while retrieving your resource. You can still complete the form below.`,
    parserUrlError: `The URL is incorrect. Please check its format.`,
    url: `Enter the web address of your resource`,
    types: {
      others: 'Other documents',
      additional_figures: 'Additional Figures',
      additional_tables: 'Additional Tables',
      figures: 'Figures',
      tables: 'Tables',
      references: 'References',
      conflicts_of_interest: 'Conflict of Interest'
    },
    uploadTypes: {
      newspaperArticle: 'Newspaper Article',
      journalArticle: 'Journal Article',
      book: 'Book',
      bookSection: 'Book Section',
      conferencePaper: 'Conference Paper',
      thesis: 'Thesis',
      preprint: 'Preprint',
      magazineArticle: 'Magazine Article',
      website: 'Website',
      dataset: 'Dataset',
      encyclopediaArticle: 'Encyclopedia Article',
      dictionaryEntry: 'Dictionary Entry',
      patent: 'Patent',
      report: 'Report'
    },
    save: 'Save'
  },
  classic: {
    title: 'title',
    legend: 'legend'
  },
  report: {
    title: "title",
    authors: "authors",
    reportNumber: "report number",
    reportType: "report type",
    seriesTitle: "series title",
    place: "place",
    institution: "institution",
    date: "date",
    pages: "pages",
    language: "language",
    shortTitle: "Short Title",
    url: "URL",
    accessed: "accessed",
    archive: "archive",
    locinarchive: "location in archive",
    libraryCatalog: "library catalog",
    callNumber: "call number",
    abstract: "abstract",
    abstractNote: "abstract note",
    extra: "extra"
  },
  thesis: {
    title: "title",
    authors: "authors",
    date: "date",
    institution: "institution",
    place: "location",
    numPages: "number of pages",
    language: "language",
    thesisType: "thesis type",
    url: "url",
    abstractNote: "abstract note",
    DOI: "DOI",
    isbn: "ISBN",
    shortTitle: "short title",
    accessed: "accessed",
    archive: "archive",
    locInArchive: "location in archive",
    libraryCatalog: "library catalog",
    callNumber: "call number",
    extra: "extra"
  },
  preprint: {
    title: "title",
    authors: "authors",
    date: "date",
    institution: "institution",
    place: "place",
    DOI: "DOI",
    url: "url",
    accessed: "accessed",
    abstract: "abstract",
    extra: "extra"
  },
  book: {
    title: "title",
    authors: "authors",
    firstname: "first name",
    lastname: "last name",
    series: "series",
    seriesNumber: "Series Number",
    volume: "volume",
    numberOfVolumes: "number of volumes",
    edition: "edition",
    place: "place",
    publisher: "publisher",
    date: "date",
    originalDate: "original date",
    numberOfPages: "number of pages",
    language: "language",
    ISBN: "ISBN",
    shortTitle: "short title",
    url: "URL",
    accessed: "accessed",
    archive: "archive",
    locinarchive: "location in archive",
    libraryCatalog: "library catalog",
    callNumber: "call number",
    abstract: "abstract",
    abstractNote: "abstract note",
    extra: "extra"
  },
  journalArticle: {
    title: "title",
    authors: "authors",
    firstname: "first name",
    lastname: "last name",
    publication: "publication",
    volume: "volume",
    issue: "issue",
    pages: "pages",
    date: "date",
    series: "series",
    seriesTitle: "series title",
    seriesText: "series text",
    journalAbbr: "journal abbreviation",
    language: "language",
    DOI: "DOI",
    ISSN: "ISSN",
    shortTitle: "short title",
    url: "URL",
    accessed: "accessed",
    archive: "archive",
    locinarchive: "location in archive",
    libraryCatalog: "library catalog",
    callNumber: "call number",
    abstract: "abstract",
    abstractNote: "abstract note",
    extra: "extra"
  },
  newspaperArticle: {
    title: "title",
    authors: "authors",
    firstname: "first name",
    lastname: "last name",
    publication: "publication",
    place: "place",
    edition: "edition",
    section: "section",
    pages: "pages",
    date: "date",
    series: "series",
    seriesTitle: "series title",
    seriesText: "series text",
    journalAbbr: "journal abbreviation",
    language: "language",
    DOI: "DOI",
    ISSN: "ISSN",
    shortTitle: "short title",
    url: "URL",
    accessed: "accessed",
    archive: "archive",
    locinarchive: "location in archive",
    libraryCatalog: "library catalog",
    callNumber: "call number",
    abstract: "abstract",
    abstractNote: "abstract note",
    extra: "extra"
  },
  magazineArticle: {
    title: "title",
    authors: "authors",
    firstname: "first name",
    lastname: "last name",
    publication: "publication",
    volume: "volume",
    issue: "issue",
    pages: "pages",
    date: "date",
    series: "series",
    seriesTitle: "series title",
    seriesText: "series text",
    journalAbbr: "journal abbreviation",
    language: "language",
    ISSN: "ISSN",
    shortTitle: "short title",
    url: "URL",
    accessed: "accessed",
    archive: "archive",
    locinarchive: "location in archive",
    libraryCatalog: "library catalog",
    callNumber: "call number",
    abstract: "abstract",
    abstractNote: "abstract note",
    extra: "extra"
  },
  webpage: {
    title: "title",
    authors: "authors",
    firstname: "first name",
    lastname: "last name",
    websiteTitle: "website title",
    websiteType: "website type",
    publisher: "publisher",
    date: "date",
    shortTitle: "short title",
    url: "URL",
    accessed: "accessed",
    abstract: "abstract",
    extra: "extra"
  },

  dataset: {
    title: "titre",
    creator: "créateur",
    contributors: "contributeurs",
    date: "date",
    version: "version",
    publisher: "éditeur",
    url: "URL",
    accessed: "consulté le",
    archive: "archive",
    locInArchive: "localisation dans l'archive",
    DOI: "DOI",
    language: "langue",
    license: "licence",
    dataFormat: "format des données",
    numberOfFiles: "nombre de fichiers",
    dataSize: "taille des données",
    abstract: "abstract",
    extra: "extra"
  },
  encyclopediaArticle: {
    title: "titre",
    author: "auteur",
    encyclopediaTitle: "titre de l'encyclopédie",
    volume: "volume",
    pages: "pages",
    date: "date",
    originalDate: "date originale",
    publisher: "éditeur",
    url: "URL",
    accessed: "consulté le",
    archive: "archive",
    locInArchive: "localisation dans l'archive",
    libraryCatalog: "catalogue de la bibliothèque",
    callNumber: "numéro d'appel",
    abstract: "abstract",
    extra: "extra"
  },
  dictionaryEntry: {
    title: "titre",
    author: "auteur",
    dictionaryTitle: "titre du dictionnaire",
    pages: "pages",
    date: "date",
    originalDate: "date originale",
    publisher: "éditeur",
    url: "URL",
    accessed: "consulté le",
    archive: "archive",
    locInArchive: "localisation dans l'archive",
    libraryCatalog: "catalogue de la bibliothèque",
    callNumber: "numéro d'appel",
    abstract: "abstract",
    extra: "extra"
  },
  patent: {
    title: "titre",
    inventor: "inventeur",
    firstname: "prénom",
    lastname: "nom",
    patentNumber: "numéro de brevet",
    date: "date",
    country: "pays",
    assignee: "titulaire du brevet",
    url: "URL",
    accessed: "consulté le",
    archive: "archive",
    locInArchive: "localisation dans l'archive",
    patentOffice: "office des brevets",
    applicationNumber: "numéro de demande",
    abstract: "abstract",
    extra: "extra"
  },

}
